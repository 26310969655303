import React, { Component } from "react";
import "./404Page.css";

import { connect } from "react-redux";
import NavBar from "../../components/navBar/navBar";

class Page404NotFound extends Component {
  render() {
    return (
      <>
        <div className="header">
          <NavBar HE />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: 100 + "vh",
            background:
              "url('https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg')"
          }}
        >
          <h1 className="error404">404</h1>
          <p
            style={{
              fontSize: "2.5rem",
              direction: "rtl",
              textAlign: "center"
            }}
          >
            אוופס... הדף שאתה מחפש לא קיים!
          </p>
          <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
            באפשרותך לנווט באמצעות התפריט
          </p>
        </div>
      </>
    );
  }
}

export default Page404NotFound;
