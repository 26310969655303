const firstReducer = (
  state = {
    isMobile: true,
    lastValues: [],
    contentEditor: null,
    language: "HE"
  },
  action
) => {
  switch (action.type) {
    case "MOBILE":
      state = {
        ...state,
        isMobile: action.payload,
        lastValues: [...state.lastValues, action.payload]
      };
      break;

    case "SETCONTENTEDITOR":
      state = {
        ...state,
        contentEditor: action.payload
      };
      break;

    case "SETLANGUAGE":
      state = {
        ...state,
        language: action.payload
      };
    default:
      break;
  }
  return state;
};

export default firstReducer;
