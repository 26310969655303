import React from "react";
import "./navBar.css";
import Dropdown from "../dropdown/dropdown";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import GreyButton from "../GreyButton/GreyButton";
import { withRouter } from "react-router-dom";
import strings from "../../localization/Localization";

class NavBar extends React.Component {
  state = {
    slideClass: "nav-links",
    animate: "",
    fixed: false,
    isMobile: false,
    mbNavBarClass: "mobile-navBar",
    userAtTop: "",
    links: {
      HE: {
        accountmonitor: [
          {
            txt: strings.financialreports,
            url: "/accountingservices"
          },
          {
            txt: strings.accountancy,
            url: "/accountingservices"
          },
          {
            txt: strings.manageledgeraccounts,
            url: "/accountingservices"
          },
          {
            txt: strings.annualreportsforbusinesses,
            url: "/accountingservices"
          },
          {
            txt: strings.Reportingtotaxauthorities,
            url: "/accountingservices"
          },
          {
            txt: strings.Taxcounseling,
            url: "/accountingservices"
          },
          {
            txt: strings.Financialstatement,
            url: "/accountingservices"
          }
        ],
        managemoney: [
          {
            txt: strings.payroll,
            url: "/accountingservices"
          },
          {
            txt: strings.FinancialManagementReport,
            url: "/accountingservices"
          },
          {
            txt: strings.cashflowmanagement,
            url: "/accountingservices"
          },
          {
            txt: strings.annualreportsforbusinesses,
            url: "/accountingservices"
          },
          {
            txt: strings.makingabusinessplan,
            url: "/accountingservices"
          }
        ],
        law: [
          {
            txt: strings.realestatelaws,
            url: "/lawservices"
          },
          {
            txt: strings.classsuits,
            url: "/lawservices"
          },
          {
            txt: strings.laborlaws,
            url: "/lawservices"
          },
          {
            txt: strings.companieslaws,
            url: "/lawservices"
          },
          {
            txt: strings.retirementplanning,
            url: "/lawservices"
          },
          {
            txt: strings.taxlaws,
            url: "/lawservices"
          },
          {
            txt: strings.localauthorities,
            url: "/lawservices"
          },
          {
            txt: strings.insurancelaws,
            url: "/lawservices"
          }
        ],
        usefulinfo: [
          {
            txt: strings.calculators,
            url: "/resources",
            param: "מחשבונים"
          },
          {
            txt: strings.documents,
            url: "/documents"
          },

          {
            txt: strings.links,
            url: "/resources",
            param: "קישורים"
          },
          {
            txt: strings.articles,
            url: "/articles"
          }
        ],
        about: [
          {
            txt: strings.Aboutsamirozan,
            url: "/about",
            param: "audit"
          },
          {
            txt: strings.we,
            url: "/about",
            param: "team"
          },
          {
            txt: strings.career,
            url: "/about",
            param: "wanted"
          }
        ]
      }
    }
  };

  ToggleSlide = () => {
    const { slideClass, animate, mbNavBarClass } = this.state;
    if (slideClass === "nav-links") {
      this.setState({ slideClass: "nav-links nav-active" });
      this.props.setNavbarActivated(true);
    } else {
      this.setState({ slideClass: "nav-links" });
      this.props.setNavbarActivated(false);
    }

    if (animate === "")
      this.setState({ animate: "navLinkFade 0.5s ease forwards" });
    else this.setState({ animate: "" });

    if (mbNavBarClass === "mobile-navBar")
      this.setState({
        mbNavBarClass: "mobile-navBar toggle",
        slideClass: "nav-links nav-active"
      });
    else this.setState({ mbNavBarClass: "mobile-navBar" });
  };

  updatePredicate = () => {
    this.setState({ isMobile: window.screen.availWidth < 1000 });
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updatePredicate);
  };

  componentDidMount = () => {
    strings.setLanguage(this.props.user.language);
    this.setState({
      links: {
        HE: {
          accountmonitor: [
            {
              txt: strings.financialreports,
              url: "/accountingservices"
            },
            {
              txt: strings.accountancy,
              url: "/accountingservices"
            },
            {
              txt: strings.manageledgeraccounts,
              url: "/accountingservices"
            },
            {
              txt: strings.annualreportsforbusinesses,
              url: "/accountingservices"
            },
            {
              txt: strings.Reportingtotaxauthorities,
              url: "/accountingservices"
            },
            {
              txt: strings.Taxcounseling,
              url: "/accountingservices"
            },
            {
              txt: strings.Financialstatement,
              url: "/accountingservices"
            }
          ],
          managemoney: [
            {
              txt: strings.payroll,
              url: "/accountingservices"
            },
            {
              txt: strings.FinancialManagementReport,
              url: "/accountingservices"
            },
            {
              txt: strings.cashflowmanagement,
              url: "/accountingservices"
            },
            {
              txt: strings.annualreportsforbusinesses,
              url: "/accountingservices"
            },
            {
              txt: strings.makingabusinessplan,
              url: "/accountingservices"
            }
          ],
          law: [
            {
              txt: strings.realestatelaws,
              url: "/lawservices"
            },
            {
              txt: strings.classsuits,
              url: "/lawservices"
            },
            {
              txt: strings.laborlaws,
              url: "/lawservices"
            },
            {
              txt: strings.companieslaws,
              url: "/lawservices"
            },
            {
              txt: strings.retirementplanning,
              url: "/lawservices"
            },
            {
              txt: strings.taxlaws,
              url: "/lawservices"
            },
            {
              txt: strings.localauthorities,
              url: "/lawservices"
            },
            {
              txt: strings.insurancelaws,
              url: "/lawservices"
            }
          ],
          usefulinfo: [
            {
              txt: strings.calculators,
              url: "/resources",
              param: "מחשבונים"
            },
            {
              txt: strings.documents,
              url: "/documents"
            },

            {
              txt: strings.links,
              url: "/resources",
              param: "קישורים"
            },
            {
              txt: strings.articles,
              url: "/articles"
            }
          ],
          about: [
            {
              txt: strings.Aboutsamirozan,
              url: "/about",
              param: "audit"
            },
            {
              txt: strings.we,
              url: "/about",
              param: "team"
            },
            {
              txt: strings.career,
              url: "/about",
              param: "wanted"
            }
          ]
        }
      }
    });

    if (strings.getLanguage() === "en")
      this.setState({
        slideClass: this.state.slideClass + " en"
      });
    window.addEventListener("resize", this.updatePredicate);
    this.updatePredicate();

    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.props.user.isMobile && window.location.pathname === "/") {
          if (window.pageYOffset <= 25) {
            this.setState(prevState => {
              if (prevState.userAtTop === "top") return { userAtTop: "" };
            });
          } else {
            this.setState(prevState => {
              if (prevState.userAtTop === "") return { userAtTop: "top" };
            });
          }
        }
      }
    };
  };

  exitNavbar = () => {
    this.setState({
      slideClass: "nav-links",
      mbNavBarClass: "mobile-navBar",
      animate: ""
    });
  };

  onHome = () => {
    this.props.history.push("/");
    this.exitNavbar();
  };

  onAbout = () => {
    this.props.history.push("/login");
    this.exitNavbar();
  };

  componentDidUpdate = () => {
    if (window.location.pathname !== "/") {
      if (this.state.userAtTop !== "top")
        this.setState({
          userAtTop: "top"
        });
    } else {
      if (this.state.userAtTop !== "" && window.pageYOffset <= 25)
        this.setState({
          userAtTop: ""
        });
    }
  };

  render() {
    const { animate, mbNavBarClass, slideClass, userAtTop, links } = this.state;
    console.log(strings.Reportingtotaxauthorities);

    return (
      <>
        <div style={{ textAlign: "center" }} className="logo">
          <div />
          <img
            onClick={this.onHome}
            src={
              this.state.isMobile
                ? "https://res.cloudinary.com/tatwertech/image/upload/v1563464283/SR_jlwcoa.png"
                : "https://res.cloudinary.com/tatwertech/image/upload/v1564224705/center_logo_site720x320_cvwrn0.png"
            }
          />
          <div
            style={{
              marginTop: 52,
              direction: strings.getLanguage() === "en" ? "ltr" : "rtl"
            }}
          >
            {/* <div
                style={{
                  display: "flex",
                  width: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <i
                  style={{
                    padding: 5,
                    marginTop: "auto",
                    marginBottom: "auto",
                    fontSize: "1.4rem"
                  }}
                  className="fas fa-phone"
                />
                <div className="shine" style={{ display: "flex" }}>
                  <h3
                    style={{
                      padding: 5,
                      marginTop: "auto",
                      marginBottom: "auto",
                      fontSize: "1.4rem"
                    }}
                  >
                    לשיחת ייעוץ ללא התחייבות
                  </h3>
                  <p
                    style={{
                      padding: 5,
                      marginTop: "auto",
                      marginBottom: "auto",
                      fontSize: "1.4rem"
                    }}
                  >
                    04-611-2525
                  </p>
                </div>
              </div> */}
            <div className={mbNavBarClass} onClick={this.ToggleSlide}>
              <div className="line1" />
              <div className="line2" />
              <div className="line3" />
            </div>
          </div>
        </div>
        <div
          className={"nav " + userAtTop}
          style={{
            boxShadow: this.props.fixed && "0 2px 35px 1px rgba(0, 0, 0, 0.3)",
            position:
              this.props.fixed && !this.state.isMobile ? "fixed" : "relative",
            marginTop:
              this.props.fixed && !this.state.isMobile
                ? 0
                : this.state.isMobile
                ? 10
                : 50,
            top: 0,
            background: this.props.fixed && "rgba(255, 255, 255, 0.96)"
          }}
        >
          <ul className={slideClass}>
            <img
              className="logo-in-navfixed"
              onClick={this.onHome}
              style={{
                height: 50,
                cursor: "pointer",
                display: this.props.fixed ? "block" : "none"
              }}
              src="https://res.cloudinary.com/tatwertech/image/upload/v1563464283/SR_jlwcoa.png"
            />
            <li style={{ animation: `${animate} 0.6s` }}>
              <div>
                <a className="effect-3" onClick={this.onHome}>
                  <span>{strings.homepage}</span>
                </a>
              </div>
            </li>
            <li style={{ position: "relative", animation: `${animate} 0.8s` }}>
              <div>
                <a style={{ position: "inherit" }} className="dropdown">
                  <Dropdown
                    lang={strings.getLanguage()}
                    mainTitle={strings.about}
                    link="/about"
                    navigate={this.props.history.push}
                    links={links.HE.about}
                  />
                </a>
              </div>
            </li>
            <li style={{ position: "relative", animation: `${animate} 1s` }}>
              <a style={{ position: "inherit" }} className="dropdown">
                <Dropdown
                  lang={strings.getLanguage()}
                  mainTitle={strings.accounting}
                  link="/accountingservices"
                  navigate={this.props.history.push}
                  links={links.HE.accountmonitor}
                />
              </a>
            </li>
            <li style={{ position: "relative", animation: `${animate} 1.2s` }}>
              <a style={{ position: "inherit" }} className="dropdown">
                <Dropdown
                  lang={strings.getLanguage()}
                  mainTitle={strings.Financialmanagement}
                  link="/accountingservices"
                  navigate={this.props.history.push}
                  links={links.HE.managemoney}
                />
              </a>
            </li>

            <li style={{ position: "relative", animation: `${animate} 1.4s` }}>
              <div>
                <a style={{ position: "inherit" }} className="dropdown">
                  <Dropdown
                    lang={strings.getLanguage()}
                    mainTitle={strings.legalservices}
                    link="/lawservices"
                    navigate={this.props.history.push}
                    links={links.HE.law}
                  />
                </a>
              </div>
            </li>

            <li style={{ position: "relative", animation: `${animate} 1.6s` }}>
              <div>
                <a style={{ position: "inherit" }} className="dropdown">
                  <Dropdown
                    lang={strings.getLanguage()}
                    mainTitle={strings.usefulinfo}
                    navigate={this.props.history.push}
                    links={links.HE.usefulinfo}
                  />
                </a>
              </div>
            </li>
            <li style={{ animation: `${animate} 1.8s` }}>
              <div>
                <a className="effect-3" onClick={this.onLogin}>
                  <span>{strings.customerlogin}</span>
                </a>
              </div>
            </li>
            <li style={{ position: "relative", animation: `${animate} 2s` }}>
              <div>
                <GreyButton
                  onPress={() => this.props.history.push("/contact")}
                  grey2
                  title={strings.contactus}
                />
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

export default connect(mapStateToProps)(withRouter(NavBar));
