import React, { Component } from "react";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import NavBar from "../../components/navBar/navBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import TeamPlayer from "../../components/TeamPlayer/TeamPlayer";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import { setContentEditor } from "../../redux/actions/firstActions";
import "./AccountingServicesPage.css";
import axios from "axios";
import EditComp from "../../components/EditComp/EditComp";
import SaveComp from "../../components/SaveComp/SaveComp";
import LoadingOverlay from "react-loading-overlay";
import strings from "../../localization/Localization";

class Advantages extends Component {
  render() {
    const { links } = this.props;

    const linkList = [];
    if (links)
      if (links.length)
        links.map((link, index) => {
          linkList.unshift(
            <span
              key={index}
              style={{
                display: "flex",
                paddingRight: strings.getLanguage() === "en" ? "0%" : "25%",
                paddingLeft: strings.getLanguage() === "en" ? "25%" : "0%",

                justifyContent: "flex-start",
              }}
            >
              <i
                style={{
                  paddingLeft: 5,
                  marginTop: "auto",
                  marginBottom: "auto",
                  color: "green",
                }}
                className="fas fa-check"
              />
              <p
                style={{
                  padding: 3,
                  textAlign: strings.getLanguage() === "en" ? "left" : "right",
                }}
                onClick={() => {
                  this.props.history.push(link.url);
                }}
              >
                <span> {link.desc} </span>
              </p>
            </span>
          );
        });

    return linkList;
  }
}

class AccountingServicesPage extends Component {
  state = {
    activated: false,
    accountingserviceList: [],
    isActive: false,
  };
  setNavbarActivated = (bool) =>
    this.setState({
      activated: bool,
    });

  getTeamPlayers = () => {
    this.startLoading();

    axios.get("https://api.sr-cpa.co.il/getaccountingservices").then((data) => {
      this.setState({
        accountingserviceList: data.data.result,
      });
    });
    this.stopLoading();
  };
  componentDidMount = () => {
    this.getTeamPlayers();
    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch((err) => {
        this.props.setContentEditor(false);
      });

    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true,
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false,
          });
      }
    };
  };

  startLoading = () =>
    this.setState({
      isActive: true,
    });

  stopLoading = () =>
    this.setState({
      isActive: false,
    });

  onChangeService = (id, title, desc) => {
    this.startLoading();
    axios
      .post("/updateaccountingservice", {
        id,
        title,
        desc,
      })
      .then((data) => {
        if (data.data.updated) {
        }
        this.stopLoading();
        this.getTeamPlayers();
      });
  };

  render() {
    const { accountingserviceList } = this.state;

    const accountingserviceListArr = [];

    if (accountingserviceList.length) {
      accountingserviceList.map((service, index) => {
        let servicesAdv = [];
        service.services.map((desc) => {
          servicesAdv.push({
            url: "/accountingservices",
            desc,
          });
        });

        accountingserviceListArr.push(
          <div key={index}>
            {this.props.user.contentEditor && this.state.editService ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "30px 30px" }}
              >
                <EditComp
                  mode={this.state.editService}
                  onClick={() =>
                    this.setState({
                      editService: false,
                      editingFor: null,
                    })
                  }
                />
                <SaveComp
                  onClick={() => {
                    this.onChangeService(
                      service._id,
                      this.state.newTitleForService,
                      this.state.newDescForService
                    );
                    this.setState({
                      editService: false,
                      editingFor: null,
                    });
                  }}
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <EditComp
                  mode={this.state.editService}
                  onClick={() =>
                    this.setState({
                      editService: true,
                      editingFor: service._id,
                      newTitleForService: service.title,
                      newDescForService: service.desc,
                    })
                  }
                />
              )
            )}
            <div
              style={{
                background:
                  'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399220/1_a0z3ic.jpg")',
                boxShadow: "rgba(150, 150, 150, 0.85) 0px 4px 10px 2px",
                margin: "25px 0px",
              }}
            >
              <TeamPlayer
                noStyle
                onChangeDesc={(val) =>
                  this.setState({
                    newDescForService: val,
                  })
                }
                onChangeTitle={(val) =>
                  this.setState({
                    newTitleForService: val,
                  })
                }
                mode={this.state.editingFor === service._id}
                title={service.title}
                icon={service.icon}
                desc={service.desc}
              />
              <div style={{ paddingBottom: 15 }}>
                <Advantages links={servicesAdv} />
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="שירותי ראיית חשבון"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />
        <div
          style={{
            paddingTop: 25,

            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "accountingserviceEN"
                : "accountingservice"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>
            <div
              className={
                strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
              }
            >
              {accountingserviceListArr}
            </div>
          </div>
        </div>

        <BlogsCarousel />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(AccountingServicesPage));
