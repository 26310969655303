import React, { Component } from "react";
import "./dropdown.css";

import { connect } from "react-redux";
import { setMobile } from "../../redux/actions/firstActions";
import { withRouter } from "react-router-dom";
import GreyButton from "../GreyButton/GreyButton";

class Dropdown extends Component {
  state = {
    isMobile: false,
    mobileDisplay: "none",
    mobileOpacity: "0",
    pHtml: []
  };

  updatePredicate = () => {
    this.setState({ isMobile: window.screen.availWidth < 1000 });
    this.props.setMobile(window.screen.availWidth < 1000);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updatePredicate);
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updatePredicate);
    this.updatePredicate();
  };

  openDropDownMobile = () => {
    if (this.state.mobileDisplay !== "block") {
      this.setState({ mobileDisplay: "block" }, () => {
        setTimeout(() => {
          this.setState({ mobileOpacity: "1" });
        }, 400);
      });
    } else this.setState({ mobileDisplay: "none", mobileOpacity: "0" });
  };

  render() {
    const { isMobile, mobileDisplay, mobileOpacity, pHtml } = this.state;
    let table = [];
    this.props.links.map((link, index) => {
      table.push(
        <p
          key={index}
          style={{
            textAlign: this.props.lang === "en" ? "left" : "right",
            direction: "ltr",
            marginLeft: this.props.lang === "en" ? "unset" : "auto",

            cursor: link.txt === "אהלן" ? "default" : "cursor"
          }}
          onClick={() => {
            if (!link.click)
              this.props.history.push(link.url, {
                param: link.param
              });
            else link.click();
          }}
        >
          {link.txt === "אהלן" && (
            <span style={{ textTransform: "uppercase" }}>
              {this.props.user1}
            </span>
          )}
          &nbsp;
          {link.txt}
        </p>
      );
      return table;
    });

    return (
      <>
        <span
          className="effect-3"
          style={{ fontSize: "1rem", height: "100%", width: "100%" }}
        >
          <span
            style={{ marginLeft: 5 }}
            onClick={() =>
              !isMobile
                ? this.props.history.push(this.props.link)
                : this.openDropDownMobile()
            }
            className="dropbtn"
          >
            {this.props.mainTitle}
            <i
              style={{ marginRight: 5 }}
              onClick={this.openDropDownMobile}
              className="fas fa-angle-down"
            />
          </span>
        </span>
        <i
          onClick={this.openDropDownMobile}
          className="fas fa-arrow-alt-circle-down"
        />
        <div
          className="dropdown-content"
          style={
            isMobile
              ? {
                  display: mobileDisplay,
                  opacity: mobileOpacity,
                  textAlign: this.props.lang === "en" ? "left" : "right"
                }
              : {
                  visibility: "none",
                  marginLeft: this.props.lang === "en" ? "unset" : "auto",

                  textAlign: this.props.lang === "en" ? "left" : "right"
                }
          }
        >
          {table}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMobile: bool => {
      dispatch(setMobile(bool));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

const withRouterDropDown = withRouter(Dropdown);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouterDropDown);
