import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./ContactUsPage.css";

import NavBar from "../../components/navBar/navBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import TeamPlayer from "../../components/TeamPlayer/TeamPlayer";
import ContactUsFloating from "../../components/ContactUsFloating/ContactUsFloating";
import { connect } from "react-redux";
import strings from "../../localization/Localization";
const AdvantageItem = props => (
  <p>
    <span>
      <i style={{ color: "green" }} className="fas fa-check" />
    </span>
    <span style={{ marginRight: 10, fontSize: "1.25rem" }}>{props.desc}</span>
  </p>
);
class ContactUsPage extends Component {
  state = {
    activated: false,
    workedYears: 33
  };
  setNavbarActivated = bool =>
    this.setState({
      activated: bool
    });
  componentDidMount = () => {
    this.setState({
      workedYears: new Date().getFullYear() - 1986
    });
    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false
          });
      }
    };
  };

  render() {
    return (
      <>
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative"
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="יצירת קשר"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />

        <div
          style={{
            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "aboutUsContainerEN"
                : "aboutUsContainer"
            }
          >
            <div style={{ zIndex: 10 }}>
              <div
                className={
                  strings.getLanguage() === "en"
                    ? "marginLeftEN width330"
                    : "marginLeft width330"
                }
                style={{
                  zIndex: 10,
                  backgroundImage:
                    'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg")',
                  boxShadow: "0px 4px 10px 2px rgba(200, 200, 200, 0.85)",
                  marginBottom: 20,
                  padding: 25,
                  paddingTop: 5,

                  height: "fit-content"
                }}
              >
                <ContactFormInPages noPadding noForm />

                <iframe
                  title="סאמי רוזאן ושות' - משרד רואי חשבון"
                  src="https://embed.waze.com/he/iframe?zoom=14&lat=32.522639&lon=35.155239&ct=livemap&pin=1"
                  style={{
                    width: "100%",
                    height: "50vh",
                    zIndex: 2
                  }}
                />
              </div>
              <WantedFormInPages style={{ textAlign: "right" }} />
            </div>
            <div
              className={
                strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
              }
              style={{
                textAlign: strings.getLanguage() === "en" ? "left" : "right"
              }}
            >
              <h1
                style={{
                  paddingTop: 10,
                  paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                  paddingLeft: strings.getLanguage() === "en" ? 20 : 0
                }}
              >
                למה אנחנו?
              </h1>
              <p
                style={{
                  marginTop: 15,
                  fontSize: "1.25rem",
                  paddingTop: 10,
                  paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                  paddingLeft: strings.getLanguage() === "en" ? 20 : 0
                }}
              >
                למה אנחנו? המשרד מטפל במגוון רחב של לקוחות בנושאי ביקורת לרבות
                ביקורת רשויות מקומיות מטעם משרד הפנים, ביקורת דוחות כספיים חברות
                פרטיות ועמותות, שירותי הנהלת חשבונות, חשבות שכר לעסקים קטנים
                ובינוניים וביקורת פנימית לעמותות .המבקרים במשרדינו מוכשרים ובעלי
                ניסיון רב בתחום עם יכולת להגיע לתוצאות ממוקדות וממצות תוך חסכון
                ניכר בשעות הביקורת מה שמביא ללקוח חסכון משמעותי
              </p>
              <div className="gridFr">
                <ul
                  style={{
                    marginTop: 15,
                    fontSize: "1.25rem",
                    paddingTop: 10,
                    paddingRight: strings.getLanguage() === "en" ? 0 : 40,
                    paddingLeft: strings.getLanguage() === "en" ? 40 : 0
                  }}
                >
                  <AdvantageItem desc="אמינות" />
                  <AdvantageItem desc="ניסיון עסקי עשיר" />
                  <AdvantageItem desc="מקצועיות" />
                  <AdvantageItem desc="זמינות גבוהה" />
                  <AdvantageItem desc="בניית אסטרטגיית מיסוי מותאמת אישית" />
                  <AdvantageItem
                    desc={`מעל ${this.state.workedYears} שנות ניסיון`}
                  />
                  <AdvantageItem desc="אוריינטציה עסקית" />
                  <AdvantageItem desc="ידע מקיף ומעמיק" />
                  <AdvantageItem desc="ליווי אישי" />
                </ul>
                <img
                  src="https://res.cloudinary.com/tatwertech/image/upload/v1564587653/adults-brainstorming-business-1595385_1_pfteeu.jpg"
                  style={{ width: "100%", marginTop: 25, objectFit: "cover" }}
                />
              </div>
              <div className="ContactContainerWidget">
                <h1>השאירו פרטים ונחזור אליכם בהקדם</h1>
                <SubtitleLines
                  ltr
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
                <ContactUsFloating />
              </div>
            </div>
          </div>
        </div>
        <BlogsCarousel />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

export default connect(mapStateToProps)(withRouter(ContactUsPage));
