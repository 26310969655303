import React, { Component } from "react";
import { withRouter } from "react-router";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import NavBar from "../../components/navBar/navBar";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import Modal from "react-awesome-modal";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import GreyButton from "../../components/GreyButton/GreyButton";
import Checkbox from "rc-checkbox";
import "./Panel.css";
import { withToastManager } from "react-toast-notifications";
import LoadingOverlay from "react-loading-overlay";

class Panel extends Component {
  state = {
    modalVisible: false,
    isActive: false,
    usernameInput: "",
    adminCheckBox: false,
    emailAddressInput: "",
    nameInput: "",
    auth: undefined,
    usersListArray: []
  };

  startLoading = () =>
    this.setState({
      isActive: true
    });

  stopLoading = () =>
    this.setState({
      isActive: false
    });

  onSignOut = () => {
    fetch("/logout")
      .then(res => res.json())
      .then(data => {
        if (data.removed) {
          this.props.toastManager.add("יצאתה מהמערכת בהצלחה", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false
          });
          setTimeout(() => {
            this.props.history.push("/");
          }, 200);
        }
      });
  };

  getAllUsers = auth => {
    this.setState({
      isActive: true
    });
    fetch("/getallusers")
      .then(res => res.json())
      .then(data => {
        if (data.ok) {
          this.setState({
            auth: auth ? true : this.state.auth,
            isActive: false,
            usersListArray: data.users
          });
        }
      });
  };

  componentDidMount = () => {
    this.setState({
      isActive: true
    });
    fetch("/checkauth")
      .then(res => res.json())
      .then(data => {
        if (data.auth) {
          if (!data.user.rootUser) this.props.history.push("/");
          else {
            this.getAllUsers(data.auth);
          }
        } else {
          this.props.history.push("/");

          this.setState({
            auth: false,
            isActive: false
          });
        }
      })
      .catch(err => {
        this.props.history.push("/");
        this.setState({
          isActive: false
        });
      });
  };

  onDeleteUser = _id => {
    this.setState({
      isActive: true
    });
    fetch("/deleteuser", {
      method: "POST",
      body: JSON.stringify({
        _id
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.deleted) {
          this.props.toastManager.add("החשבון נמחק בהצלחה", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false
          });
          this.getAllUsers();
        }
      })
      .catch(err => {
        this.props.toastManager.add("תנסה שוב מאוחר יותר", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false
        });
      });
  };

  onAddUser = () => {
    const {
      adminCheckBox,
      emailAddressInput,
      usernameInput,
      nameInput
    } = this.state;

    if (usernameInput.length) {
      if (emailAddressInput.length) {
        this.startLoading();
        this.setState({
          modalVisible: false
        });
        fetch("/adduser", {
          method: "POST",
          body: JSON.stringify({
            emailaddress: emailAddressInput,
            admin: adminCheckBox,
            username: usernameInput,
            name: nameInput
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(data => {
            if (data.added) {
              this.stopLoading();
              this.props.toastManager.add("הוספתה המשתמש בהצלחה!", {
                appearance: "success",
                autoDismiss: true,
                pauseOnHover: false
              });
            } else if (data.auth === false) {
              this.stopLoading();
              this.props.toastManager.add("אין לך גישה!", {
                appearance: "error",
                autoDismiss: true,
                pauseOnHover: false
              });
            }
          })
          .catch(err => {
            this.stopLoading();

            this.props.toastManager.add("תנסה שוב מאוחר יותר", {
              appearance: "error",
              autoDismiss: true,
              pauseOnHover: false
            });
          });
      } else {
        this.props.toastManager.add("דואר אלקטרוני אינו תקין", {
          appearance: "error",
          autoDismiss: true,
          pauseOnHover: false
        });
      }
    } else {
      this.props.toastManager.add("שם משתמש אינו תקין", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false
      });
    }
  };

  openModal = () =>
    this.setState({
      modalVisible: true
    });

  closeModal = () =>
    this.setState({
      modalVisible: false
    });

  render() {
    const { adminCheckBox, modalVisible, auth, usersListArray } = this.state;

    let usersList = [];
    if (usersListArray.length)
      usersListArray.map((user, index) => {
        usersList.push(
          <div
            key={index}
            style={{
              display: "grid",
              direction: "rtl",
              gridTemplateColumns: "70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
              background: index % 2 === 0 && "#FCF8E3",
              border: "1px black solid",
              borderBottom: 0,
              padding: 10,
              textAlign: "center"
            }}
          >
            <h4>{index + 1}</h4>
            <h4>{user.name}</h4>
            <h4>{user.username}</h4>
            <h4>{user.emailAddress}</h4>
            <h4>{user.LastLogged}</h4>
            <h4>{user.CreatedDate}</h4>
            <h4>{user.rootUser ? "כן" : "לא"}</h4>
            <i
              style={{
                fontSize: "1.17rem",
                color: "red",
                width: "fit-content",
                margin: "auto auto",
                cursor: "pointer"
              }}
              onClick={() => this.onDeleteUser(user._id)}
              className="fas fa-minus-circle"
            />
          </div>
        );
      });

    if (!auth)
      return (
        <>
          <LoadingOverlay active spinner text="ממתין.." />
          <div style={{ height: "100vh", background: "white" }} />
        </>
      );
    return (
      <div>
        <LoadingOverlay active={this.state.isActive} spinner text="ממתין.." />
        <Modal
          visible={modalVisible}
          width="400"
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          <div style={{ padding: 25, direction: "rtl" }}>
            <h2 style={{ textAlign: "center" }}>
              הוספת משתמש למערכת ניהול התוכן
            </h2>
            <SubtitleLines
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 15
              }}
            />
            <p style={{ padding: 5 }}>
              <span style={{ minWidth: 90, display: "inline-block" }}>
                שם מלא:
              </span>

              <input
                placeholder="John Smith"
                value={this.state.nameInput}
                onChange={({ target }) =>
                  this.setState({
                    nameInput: target.value
                  })
                }
                style={{
                  border: "none",
                  marginRight: 15,
                  padding: 5,
                  fontSize: "1rem",
                  background: "transparent",
                  borderBottom: "1px black solid"
                }}
              />
            </p>
            <p style={{ padding: 5 }}>
              <span style={{ minWidth: 90, display: "inline-block" }}>
                תעודת זהות:
              </span>

              <input
                placeholder="321765925"
                value={this.state.usernameInput}
                onChange={({ target }) =>
                  this.setState({
                    usernameInput: target.value
                  })
                }
                style={{
                  border: "none",
                  marginRight: 15,
                  padding: 5,
                  fontSize: "1rem",
                  background: "transparent",
                  borderBottom: "1px black solid"
                }}
              />
            </p>
            <p style={{ padding: 5 }}>
              <span style={{ minWidth: 90, display: "inline-block" }}>
                דואר אלקטרוני:
              </span>

              <input
                placeholder="karam@example.com"
                value={this.state.emailAddressInput}
                onChange={({ target }) =>
                  this.setState({
                    emailAddressInput: target.value
                  })
                }
                style={{
                  border: "none",
                  marginRight: 15,
                  padding: 5,
                  fontSize: "1rem",
                  background: "transparent",
                  borderBottom: "1px black solid"
                }}
              />
            </p>
            <p style={{ padding: 5 }}>
              <span>
                <Checkbox
                  checked={adminCheckBox}
                  onChange={state =>
                    this.setState({
                      adminCheckBox: !adminCheckBox
                    })
                  }
                />
              </span>
              <span style={{ marginRight: 15 }}>
                יש לו גישה להוסיף ולמחוק משתמשים במערכת?
              </span>
            </p>
            <GreyButton
              grey2
              onPress={this.onAddUser}
              title="Add"
              style={{ margin: "20px auto", display: "block" }}
            />
          </div>
        </Modal>
        <TopAnnouncementBar />
        <EnglishBtn />
        <div style={{ height: "35vh" }}>
          <div
            style={{
              overflow: "hidden",
              position: "relative"
            }}
          >
            <NavBar
              fixed={this.state.fixed}
              setNavbarActivated={this.setNavbarActivated}
              HE
            />
          </div>
        </div>
        <ImageBanner
          title="ניהול משתמשים"
          style={{
            marginTop: -50
          }}
          src="https://media.licdn.com/dms/image/C4D1BAQEOb4cfa7FY3w/company-background_10000/0?e=2159024400&v=beta&t=azIqkgnWl_JR1hm84PvCtRC1S4CTD7ArakuvhSEQWxo"
        />
        <div
          style={{
            padding: 20,
            background:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <div
            style={{
              width: "90%",
              boxShadow: "0px 4px 10px 2px rgba(200,200,200,0.85)",
              direction: "rtl",
              padding: 20,
              background: "white",
              margin: "0 auto"
            }}
          >
            <div style={{ width: "100%", display: "flex", marginBottom: 15 }}>
              <i
                onClick={this.openModal}
                style={{
                  fontSize: "1.75rem",
                  color: "green",
                  cursor: "pointer"
                }}
                className="fas fa-plus"
              />
              <i
                style={{
                  fontSize: "1.75rem",
                  color: "#C2B59B",
                  cursor: "pointer",
                  marginRight: 10
                }}
                onClick={this.getAllUsers}
                className="fas fa-sync"
              />
              <div
                onClick={this.onSignOut}
                style={{ marginRight: "auto", cursor: "pointer" }}
              >
                <span style={{ fontSize: "1.75rem", marginLeft: 15 }}>
                  יציאה
                </span>
                <i
                  style={{
                    fontSize: "1.75rem",
                    color: "red",

                    transform: "rotate(180deg)"
                  }}
                  className="fas fa-sign-out-alt"
                />
              </div>
            </div>

            <div
              style={{
                display: "grid",
                direction: "rtl",
                gridTemplateColumns: "70px 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                background: "black",
                color: "white",
                padding: 10,
                textAlign: "center"
              }}
            >
              <h4>#</h4>
              <h4>שם מלא</h4>
              <h4>תעודת זהות</h4>
              <h4>דואר אלקטרוני</h4>
              <h4>פעם אחרונה נכנס</h4>
              <h4>תאריך פתיחת המשתמש</h4>
              <h4>מנהל</h4>
              <h4>אופציות</h4>
            </div>

            {usersList.length ? (
              <div style={{ borderBottom: "1px black solid" }}>{usersList}</div>
            ) : (
              <h1 style={{ textAlign: "center", marginTop: 15 }}>
                אין משתמשים במערכת
              </h1>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(withRouter(Panel));
