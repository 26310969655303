import React from "react";
import "./ContactUsFloating.css";
import strings from "../../localization/Localization";

const ContactUsFloating = props => {
  return (
    <div className="contactUsFloatingContainer">
      <form
        className="contactUsFloating"
        autoComplete="off"
        action="https://formspree.io/info@sr-cpa.co.il"
        method="POST"
      >
        <div className="grid">
          <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
            <input
              style={{ background: "transparent" }}
              placeholder=" "
              autoComplete="off"
              required
              name="fullname"
            />

            <label htmlFor="fullname">{strings.firstname}</label>
          </div>
          <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
            <input
              style={{ background: "transparent" }}
              placeholder=" "
              name="lastname"
            />

            <label htmlFor="lastname">{strings.lastname}</label>
          </div>
          <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
            <input
              style={{ background: "transparent" }}
              placeholder=" "
              required
              type="email"
              name="email"
            />

            <label htmlFor="email">{strings.emailaddress}</label>
          </div>
          <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
            <input
              style={{ background: "transparent" }}
              placeholder=" "
              type="phone"
              name="phone"
            />
            <label htmlFor="message">{strings.phone}</label>
          </div>
        </div>
        <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
          <textarea
            style={{ background: "transparent" }}
            placeholder=" "
            required
            name="message"
          />
          <label htmlFor="message">{strings.message}</label>
        </div>
        <div className={props.ltr ? "innerGridEn" : "innerGrid"}>
          <button style={{ width: "100%" }} type="submit">
            {strings.send}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsFloating;
