import React from "react";
import "./TeamPlayer.css";
import strings from "../../localization/Localization";

const TeamPlayer = props => {
  return (
    <div
      style={
        !props.noStyle
          ? {
              boxShadow: "0px 4px 10px 2px rgba(150,150,150,0.85)",
              margin: "25px 0px",
              backgroundImage:
                'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399220/1_a0z3ic.jpg")'
            }
          : {
              backgroundImage:
                'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399220/1_a0z3ic.jpg")'
            }
      }
    >
      <div
        className="teamPlayerContainer"
        style={{ direction: strings.getLanguage() === "en" ? "ltr" : "rtl" }}
      >
        <div
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative"
          }}
        >
          <div
            style={{
              height: 125,
              width: 125,
              position: "relative"
            }}
          >
            {props.src ? (
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  background: "transparent",
                  objectFit: "cover"
                }}
                src={props.src}
              />
            ) : props.icon ? (
              <i
                style={{
                  margin: !props.icon.includes("flaticon") && 25,
                  color: "rgb(173, 127, 87)",
                  fontSize: "4rem"
                }}
                className={props.icon}
              />
            ) : (
              <> </>
            )}
          </div>
        </div>
        <div>
          {props.mode ? (
            <h2
              contentEditable
              onInput={({ target }) => props.onChangeTitle(target.innerText)}
              suppressContentEditableWarning
              style={{
                paddingRight: 25,
                border: "1px black solid",
                paddingLeft: 25,
                marginTop: 15
              }}
            >
              {props.title.includes("סאמי") ? (
                <>
                  <span> {props.title} </span>
                  <span>
                    <i
                      style={{ paddingRight: 5 }}
                      className="fas fa-balance-scale"
                    />
                  </span>
                </>
              ) : (
                props.title
              )}
            </h2>
          ) : (
            <h2
              className={props.noStyle && "paddingleftandright"}
              style={{ paddingRight: 25, paddingLeft: 25, marginTop: 15 }}
            >
              {props.title.includes("סאמי") ? (
                <>
                  <span> {props.title} </span>
                  <span>
                    <i
                      style={{ paddingRight: 5 }}
                      className="fas fa-balance-scale"
                    />
                  </span>
                </>
              ) : (
                <span> {props.title} </span>
              )}
            </h2>
          )}

          <div className={props.noStyle && "padding25"} style={{ padding: 25 }}>
            {props.mode && !props.noStyle ? (
              <p
                contentEditable
                suppressContentEditableWarning
                onInput={({ target }) => props.onChangeDesc(target.innerText)}
                style={{
                  fontSize: "1.25rem",
                  textAlign: "right",
                  border: "1px black solid"
                }}
                dangerouslySetInnerHTML={{ __html: props.desc }}
              />
            ) : (
              !props.noStyle && (
                <span>
                  <p
                    style={{ fontSize: "1.25rem", textAlign: "justify" }}
                    dangerouslySetInnerHTML={{ __html: props.desc }}
                  />
                </span>
              )
            )}
            {props.noStyle && (
              <span>
                <p style={{ fontSize: "1.25rem", textAlign: "justify" }}>
                  מה אנחנו מציעים עבורך
                </p>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPlayer;
