import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import GreyButton from "../../components/GreyButton/GreyButton";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import NavBar from "../../components/navBar/navBar";
import LoadingOverlay from "react-loading-overlay";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";

class PanelLogin extends Component {
  state = {
    userNameInput: "",
    passwordInput: "",
    msgColor: "white",
    msg: "TatwerTech Panel",
    isActive: false,
    auth: undefined
  };

  componentWillMount = () => {
    this.fetchCheckAuth();
  };

  startLoading = () =>
    this.setState({
      isActive: true
    });

  stopLoading = () =>
    this.setState({
      isActive: false
    });

  fetchCheckAuth = () => {
    this.startLoading();
    fetch("/checkauth")
      .then(res => res.json())
      .then(data => {
        this.stopLoading();
        if (data.auth) {
          if (data.user.rootUser) this.props.history.push("/panel");
          else this.props.history.push("/");
        } else {
          this.setState({
            auth: false
          });
        }
      })
      .catch(err => {
        this.stopLoading();
      });
  };
  onSubmit = () => {
    const { userNameInput, passwordInput } = this.state;
    if (userNameInput === "")
      return this.setState({
        msg: "לא הזנתה שם משתמש",
        msgColor: "red"
      });
    else if (passwordInput === "")
      return this.setState({
        msg: "לא הזנתה סיסמה",
        msgColor: "red"
      });
    else {
      this.startLoading();

      fetch("/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username: userNameInput,
          password: passwordInput
        })
      })
        .then(res => res.json())
        .then(data => {
          if (data.auth) {
            this.stopLoading();
            if (data.user !== undefined) {
              if (data.user.rootUser) window.location.href = "/panel";
              else window.location.href = "/";
            } else window.location.href = "/";
          } else {
            this.stopLoading();
            this.setState({
              msg: "לא ניתן לכנס",
              msgColor: "red"
            });
          }
        })
        .catch(err => {
          this.stopLoading();
        });
    }
  };

  render() {
    const { userNameInput, passwordInput, msg, auth } = this.state;
    if (auth === undefined)
      return (
        <>
          <LoadingOverlay spinner text="ממתין.." />
        </>
      );
    return (
      <>
        <LoadingOverlay active={this.state.isActive} spinner text="ממתין.." />
        <TopAnnouncementBar />
        <EnglishBtn />
        <div
          style={{
            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative"
            }}
          >
            <NavBar
              fixed={this.state.fixed}
              setNavbarActivated={this.setNavbarActivated}
              HE
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 20,
            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <img
            style={{
              marginLeft: "auto",
              textAlign: "center",
              marginRight: "auto"
            }}
            height="200px"
            src={require("../../publicStyles/images/tatwertechlogo.png")}
          />

          <div
            style={{
              background: "#8A96CC",
              width: 310,
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 5,
              boxShadow: "#8A96CC 0px 8px 15px"
            }}
          >
            <h1 style={{ color: "white", fontSize: "1.4rem", paddingTop: 15 }}>
              כניסה למערכת ניהול התוכן
            </h1>
            <form
              style={{
                textAlign: "center",

                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <input
                placeholder="שם משתמש"
                value={userNameInput}
                onChange={({ target }) =>
                  this.setState({
                    userNameInput: target.value
                  })
                }
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  border: "none",
                  width: "80%",
                  marginTop: 5,
                  fontSize: "1.2em",
                  padding: 10,
                  textAlign: "center",
                  borderRadius: 5,
                  marginBottom: 5
                }}
              />
              <input
                type="password"
                placeholder="סיסמה"
                value={passwordInput}
                onChange={({ target }) =>
                  this.setState({
                    passwordInput: target.value
                  })
                }
                style={{
                  display: "block",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  fontSize: "1.2em",
                  border: "none",
                  width: "80%",
                  padding: 10,
                  marginTop: 5,
                  borderRadius: 5,
                  marginBottom: 5
                }}
              />

              <GreyButton
                onPress={this.onSubmit}
                purple
                style={{ width: "80%", padding: 2, fontSize: "1.2em" }}
                type="button"
                title="כניסה"
              />
            </form>
            <h3
              style={{
                color: this.state.msgColor,
                fontWeight: "normal",
                paddingBottom: 10
              }}
            >
              {msg}
            </h3>
          </div>
        </div>
      </>
    );
  }
}

const withRouterPanelLogin = withRouter(PanelLogin);

export default withRouterPanelLogin;
