import React, { Component } from "react";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import NavBar from "../../components/navBar/navBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import TeamPlayer from "../../components/TeamPlayer/TeamPlayer";
import { connect } from "react-redux";
import "./BlogPage.css";
import EditComp from "../../components/EditComp/EditComp";
import { withToastManager } from "react-toast-notifications";
import { withRouter } from "react-router-dom";
import { setContentEditor } from "../../redux/actions/firstActions";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  convertToRaw,
  ContentState,
  EditorState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import axios from "axios";
import SaveComp from "../../components/SaveComp/SaveComp";
import LoadingOverlay from "react-loading-overlay";
import GreyButton from "../../components/GreyButton/GreyButton";
import strings from "../../localization/Localization";
var stateFromHTML = require("draft-js-import-html").stateFromHTML;

class BlogPage extends Component {
  state = {
    activated: false,
    Error: false,
    authorInput: "",
    newTitleInput: "",
    isActive: false,
    file: null,
    editMode: false,
    editorState: EditorState.createEmpty(),
  };
  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  addArticle = (title, author, html, imgsrc) => {
    this.setState({
      isActive: true,
    });

    if (title && author && html && imgsrc) {
      const fd = new FormData();
      fd.append("file", this.state.file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios
        .post("https://api.sr-cpa.co.il/upload", fd, config)
        .then((response) => {
          axios
            .post("https://api.sr-cpa.co.il/addarticle", {
              title,
              author,
              html,
              src: response.data.src,
              imgsrc,
            })
            .then((data) => {
              if (data.status === 200) {
                if (data.data.added) {
                  this.props.toastManager.add("SUCCESS", {
                    appearance: "success",
                    autoDismiss: true,
                    pauseOnHover: false,
                  });
                  this.setState({
                    isActive: false,
                  });
                  setTimeout(() => this.props.history.push("/articles"), 300);
                }
              }
            });
        });
    } else {
      this.props.toastManager.add("Error", {
        appearance: "error",
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  setNavbarActivated = (bool) =>
    this.setState({
      activated: bool,
    });

  editArticle = (id, title, html) => {
    this.setState({
      isActive: true,
    });
    axios
      .post("https://api.sr-cpa.co.il/editarticle", {
        id,
        title,
        html,
      })
      .then((data) => {
        if (data.status === 200) {
          if (data.data.updated) {
            this.props.toastManager.add("SUCCESS", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
            axios
              .post("https://api.sr-cpa.co.il/getarticle", {
                id: this.state.id,
              })
              .then((data) => {
                if (data.status === 200) {
                  this.setState({
                    title: data.data.result.title,
                    desc: data.data.result.desc,
                    src: data.data.result.src,
                    date: data.data.result.date,
                    author: data.data.result.author,
                    imgsrc: data.data.result.imgsrc,
                  });
                  this.setState({
                    isActive: false,
                  });
                }
              });
          }
        }
      })
      .catch((err) => alert(err));
  };

  deleteArticle = (id) => {
    this.setState({
      isActive: true,
    });
    axios
      .post("https://api.sr-cpa.co.il/deletearticle", {
        id,
      })
      .then((data) => {
        if (data.status === 200) {
          if (data.data.deleted) {
            this.props.toastManager.add("SUCCESS", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
            this.setState({
              isActive: false,
            });

            setTimeout(() => {
              this.props.history.push("/articles");
            }, 300);
          }
        }
      });
  };
  componentDidMount = () => {
    this.setState({
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML("<p>example text..</p>")
        )
      ),
    });
    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }
          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch((err) => alert(err));

    if (window.location.search.length) {
      if (window.location.search.includes("id=")) {
        let id = window.location.search.split("id=")[1];

        if (id[id.length - 1] === "&") id = id.slice(0, id.length - 1);

        this.setState({
          id,
          isActive: true,
        });
        axios
          .post("https://api.sr-cpa.co.il/getarticle", {
            id,
          })
          .then((data) => {
            this.setState({
              isActive: false,
            });
            if (data.status === 200) {
              this.setState({
                title: data.data.result.title,
                desc: data.data.result.desc,
                src: data.data.result.src,
                date: data.data.result.date,
                author: data.data.result.author,
                imgsrc: data.data.result.imgsrc,
              });
            }
          });
      }
    } else {
      this.setState({
        Error:
          this.props.location !== undefined &&
          this.props.location.state !== undefined &&
          this.props.location.state.addMode
            ? false
            : true,
      });
    }
    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true,
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false,
          });
      }
    };
  };
  render() {
    const { editMode } = this.state;
    return (
      <>
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="מאמרים"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />
        <div
          style={{
            paddingTop: 25,

            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "accountingserviceEN"
                : "accountingservice"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>
            <div
              className={
                strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
              }
            >
              {this.props.location !== undefined &&
              this.props.location.state !== undefined &&
              this.props.location.state.addMode &&
              this.props.user.contentEditor ? (
                <>
                  <LoadingOverlay active={this.state.isActive} text="ממתין.." />
                  <div style={{ padding: 15 }}>
                    <h1 style={{ textAlign: "center" }}>הוספת מאמר חדש</h1>
                    <input
                      onChange={({ target }) =>
                        this.setState({
                          newTitleInput: target.value,
                        })
                      }
                      value={this.state.newTitleInput}
                      style={{
                        background: "transparent",
                        border: "none",
                        border: 0,
                        borderBottom: "1px black solid",
                        fontSize: "1.1rem",
                        marginTop: 15,
                        display: "block",
                      }}
                      placeholder="שם המאמר"
                    />
                    <input
                      onChange={({ target }) =>
                        this.setState({
                          authorInput: target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        border: "none",
                        border: 0,
                        borderBottom: "1px black solid",
                        fontSize: "1.1rem",
                        marginTop: 15,
                        display: "block",
                      }}
                      placeholder="שם הכותב"
                      value={this.state.authorInput}
                    />
                    <div style={{ marginTop: 10 }}>
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => {
                          this.setState({
                            file: e.target.files[0],
                          });
                        }}
                      />
                    </div>
                    <input
                      onChange={({ target }) =>
                        this.setState({
                          imageSrc: target.value,
                        })
                      }
                      style={{
                        background: "transparent",
                        border: "none",
                        border: 0,
                        borderBottom: "1px black solid",
                        fontSize: "1.1rem",
                        marginTop: 15,
                        display: "block",
                      }}
                      placeholder="מקור התמונה"
                      value={this.state.imageSrc}
                    />
                    <div style={{ margin: "10px 0px" }}>
                      <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                    <GreyButton
                      purple
                      style={{ padding: "8px 30", fontSize: "1.25rem" }}
                      title="הוסף"
                      onPress={() =>
                        this.addArticle(
                          this.state.newTitleInput,
                          this.state.authorInput,
                          draftToHtml(
                            convertToRaw(
                              this.state.editorState.getCurrentContent()
                            )
                          ),
                          this.state.imageSrc
                        )
                      }
                    />
                  </div>
                </>
              ) : this.state.Error ? (
                <h1 style={{ textAlign: "center" }}>אין מאמר כזה כעת!</h1>
              ) : !this.state.desc ? (
                <LoadingOverlay active text="ממתין.." />
              ) : (
                <>
                  <LoadingOverlay active={this.state.isActive} text="ממתין.." />

                  <h1 style={{ textAlign: "center" }}>
                    {this.props.user.contentEditor && this.state.editMode ? (
                      <>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "30px 30px",
                          }}
                        >
                          <EditComp
                            mode={this.state.editMode}
                            onClick={() =>
                              this.setState({
                                editMode: !this.state.editMode,
                                titleInput: this.state.title,
                                editorState: EditorState.createWithContent(
                                  ContentState.createFromBlockArray(
                                    convertFromHTML(this.state.desc)
                                  )
                                ),
                              })
                            }
                          />
                          <SaveComp
                            onClick={() => {
                              this.setState({
                                editMode: false,
                              });
                              this.editArticle(
                                this.state.id,
                                this.state.titleInput,
                                draftToHtml(
                                  convertToRaw(
                                    this.state.editorState.getCurrentContent()
                                  )
                                )
                              );
                            }}
                          />
                        </div>
                        <GreyButton
                          style={{
                            marginLeft: "auto",
                            display: "block",
                            margin: "10px 0",
                          }}
                          grey2
                          onPress={() => this.deleteArticle(this.state.id)}
                          title="למחוק מאמר"
                        />
                      </>
                    ) : (
                      this.props.user.contentEditor && (
                        <>
                          <EditComp
                            mode={this.state.editMode}
                            onClick={() =>
                              this.setState({
                                titleInput: this.state.title,
                                editMode: !this.state.editMode,
                                editorState: EditorState.createWithContent(
                                  ContentState.createFromBlockArray(
                                    convertFromHTML(this.state.desc)
                                  )
                                ),
                              })
                            }
                          />
                          <GreyButton
                            style={{
                              marginLeft: "auto",
                              display: "block",
                              margin: "10px 0",
                            }}
                            grey2
                            title="למחוק מאמר"
                            onPress={() => this.deleteArticle(this.state.id)}
                          />
                        </>
                      )
                    )}
                    <div style={{ padding: 15 }}>
                      <img
                        style={{
                          width: "100%",
                          height: 300,
                          objectFit: "cover",
                        }}
                        src={this.state.src}
                      />
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#999",
                          textAlign: "right",
                        }}
                      >
                        <span> מקור התמונה: </span>
                        <span> {this.state.imgsrc} </span>
                      </p>
                    </div>
                    {editMode ? (
                      <div style={{ border: "1px block solid" }}>
                        <span
                          onInput={({ target }) =>
                            this.setState({
                              titleInput: target.innerText,
                            })
                          }
                          suppressContentEditableWarning
                          contentEditable
                          style={{
                            border: "1px black solid",
                            display: "block",
                            padding: "0px 40px",
                            width: "fit-content",
                            background: "transparent",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {this.state.title}
                        </span>
                      </div>
                    ) : (
                      <span
                        style={{
                          border: "1px transparent solid",
                          display: "block",
                          width: "fit-content",
                          background: "transparent",
                          marginLeft: "auto",
                          paddingRight: 20,
                        }}
                      >
                        <span> {this.state.title} </span>
                      </span>
                    )}
                  </h1>
                  {editMode ? (
                    <div style={{ margin: "10px 0px" }}>
                      <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: 25,
                        paddingLeft: 25,
                        direction: "unset",
                        marginBottom: 25,
                      }}
                      dangerouslySetInnerHTML={{ __html: this.state.desc }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <BlogsCarousel />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouter(BlogPage)));
