import React, { Component } from "react";
import "./homePage.css";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import { connect } from "react-redux";
import { setContentEditor } from "../../redux/actions/firstActions";
import ReactTypingEffect from "react-typing-effect";
import Slider from "react-slick";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Service from "../../components/Service/Service";
import User from "../../components/User/User";
import { Parallax, Background } from "react-parallax";
import CountUp, { startAnimation } from "react-countup";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import VisibilitySensor from "react-visibility-sensor";
import Specialization from "../../components/Specialization/Specialization.js";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import ContactUsFloating from "../../components/ContactUsFloating/ContactUsFloating";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import LoadingOverlay from "react-loading-overlay";
import { withToastManager } from "react-toast-notifications";

// {
/* <div
style={{
  display: "grid",
  justifyContent: "center",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  gridTemplateColumns: "repeat(auto-fit, minmax(5rem, 1fr))",
  marginTop: 50
}}
>
<div><i class="fab fa-acquisitions-incorporated"></i></div>
<div>Hey</div>
<div>Hey</div>
<div>Hey</div>
<div>Hey</div>
<div>Hey</div>
<div>Hey</div>
<div>Hey</div>
</div> */
// }
import ScrollAnimation from "react-animate-on-scroll";
import NavBar from "../../components/navBar/navBar";
import { Board } from "../../components/tictactoe/tictactoe";
import GreyButton from "../../components/GreyButton/GreyButton";
import Reviewer from "../../components/Reviewer/Reviewer";
import WorkingField from "../../components/WorkingField/WorkingField";
import BlogItem from "../../components/BlogItem/BlogItem";
import EditComp from "../../components/EditComp/EditComp";
import LocalizedStrings from "react-localization";
import strings from "../../localization/Localization";
import SaveComp from "../../components/SaveComp/SaveComp";
var Scroll = require("react-scroll");

var Element = Scroll.Element;
var scroller = Scroll.scroller;
const AdvantageItem = (props) => (
  <p>
    <span>
      <i style={{ color: "green" }} className="fas fa-check" />
    </span>
    <span style={{ marginRight: 10, fontSize: "1.25rem" }}>{props.desc}</span>
  </p>
);

const DateComponent = (props) => (
  <div
    style={{
      width: 150,
    }}
  >
    <div
      className={
        strings.getLanguage() === "en" ? "dateComponentEN" : "dateComponent"
      }
      style={{ background: props.background }}
    >
      {props.mode ? (
        <p
          onInput={({ target }) => props.setDateInput(target.innerText)}
          suppressContentEditableWarning
          contentEditable="plaintext-only"
          style={{
            padding: "10px 15px",
            width: "max-content",
            marginLeft: "auto",
            border: "1px black solid",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          {props.title}
        </p>
      ) : (
        <p
          style={{
            padding: "10px 15px",
            width: "max-content",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <span> {props.title} </span>
        </p>
      )}
    </div>
    {props.title.length <= 4 ? (
      <div className="dateComp" />
    ) : (
      <div className="dateComp" />
    )}
  </div>
);

class HomePageAr extends Component {
  state = {
    servicesList: [],
    allReviewers: [],
    lawsServices: [],
    selectedItem: 0,
    allTimelines: [],
    hello: strings.goodmorning,
    duration: 6,
    activated: false,
    serviceTitleInput: "",
    titleSectionBoss: "",
    descSectionBoss: "",
    duration1: 7,
    workedYears: null,
    aboutUsFirstPr: "",
    aboutUsFirstPr1: "",
    aboutUsFirstPr2: "",
    onGetAboutUs: [
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d46b3fd1c9d440000b09495",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d46bb361c9d440000b09496",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d46bb431c9d440000b09497",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d46dc291c9d44000025f82f",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d46dc591c9d44000025f831",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d471e2a1c9d440000b022f5",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d471ecf1c9d440000b022f6",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d471fe01c9d440000b022f7",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4720041c9d440000b022f8",
      }),
    ],
  };

  setNavbarActivated = (bool) =>
    this.setState({
      activated: bool,
    });

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (this.myCountUp && this.myCountUp1) {
        this.setState({
          duration: this.state.duration === 6 ? 5 : 6,
          duration1: this.state.duration1 === 7 ? 8 : 7,
        });
      }
      this.setState({ scrollStatus: false });
    }
  };

  addService = () => {
    this.startLoading();
    const { newServiceInput, serviceEditingFor } = this.state;
    if (newServiceInput.length && serviceEditingFor) {
      axios
        .post("/addservice", {
          serviceEditingFor,
          newServiceInput,
        })
        .then((data) => {
          this.stopLoading();
          if (data.status === 200) {
            this.props.toastManager.add("SUCCESS", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
          }
          this.getServices();
        });
    }
  };

  startLoading = () =>
    this.setState({
      isActive: true,
    });

  stopLoading = () =>
    this.setState({
      isActive: false,
    });

  changeWhyUs = (title, desc) => {
    this.updateContent(
      [
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: title.id,
          desc: title.title,
        }),
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: desc.id,
          desc: desc.title,
        }),
      ],
      [
        axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
          id: title.id,
        }),
        axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
          id: desc.id,
        }),
      ],
      [
        {
          id: title.id,
          desc: title.title,
        },
        {
          id: desc.id,
          desc: desc.title,
        },
      ]
    );
  };

  onSaveChangeAboutUs = () => {
    const { aboutUsFirstPr, aboutUsFirstPr1, aboutUsFirstPr2 } = this.state;

    if (
      aboutUsFirstPr.length &&
      aboutUsFirstPr1.length &&
      aboutUsFirstPr2.length
    ) {
      this.updateContent(
        [
          axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
            id: "5d46b3fd1c9d440000b09495",
            desc: this.state.aboutUsFirstPr,
          }),
          axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
            id: "5d46bb361c9d440000b09496",
            desc: this.state.aboutUsFirstPr1,
          }),
          axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
            id: "5d46bb431c9d440000b09497",
            desc: this.state.aboutUsFirstPr2,
          }),
        ],
        [
          axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
            id: "5d46b3fd1c9d440000b09495",
          }),
          axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
            id: "5d46bb361c9d440000b09496",
          }),
          axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
            id: "5d46bb431c9d440000b09497",
          }),
        ],
        [
          {
            id: "5d46bb431c9d440000b09497",
            desc: this.state.aboutUsFirstPr2,
          },
          {
            id: "5d46bb361c9d440000b09496",
            desc: this.state.aboutUsFirstPr1,
          },
          {
            id: "5d46b3fd1c9d440000b09495",
            desc: this.state.aboutUsFirstPr,
          },
        ]
      );
    }
  };

  editReviewer = (id, name, desc) => {
    if (name.length && desc.length && id.length) {
      this.startLoading();
      axios
        .post("/editreviewer", {
          id,
          name,
          desc,
        })
        .then((data) => {
          if (data.data.updated)
            this.props.toastManager.add("SUCCESS", {
              appearance: "success",
              autoDismiss: true,
              pauseOnHover: false,
            });
          this.getAllReviewers();
          this.stopLoading();
        });
    }
  };

  getAllReviewers = () => {
    axios.get("https://api.sr-cpa.co.il/getallreviewers").then((data) => {
      if (data.status === 200)
        this.setState({
          allReviewers: data.data.result,
        });
    });
  };

  getAllTimeLines = () => {
    axios.get("https://api.sr-cpa.co.il/getalltimelines").then((data) => {
      if (data.status === 200)
        this.setState({
          allTimelines: data.data.result,
        });
    });
  };

  updateContent = (array, array2, array3) => {
    this.startLoading();
    axios.all(array).then((responses) => {
      this.stopLoading();

      this.props.toastManager.add("SUCCESS", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false,
      });

      // this.getContent(array2);

      if (array3) {
        array3.map((obj) => {
          const objPId = "p" + obj.id;
          this.setState({
            [objPId]: obj.desc,
          });
        });
      }
    });
  };

  getContent = (array) => {
    this.startLoading();
    axios.all(array).then((responses) => {
      responses.map((response) => {
        const responseId = "p" + response.data.result._id;
        this.setState({
          [responseId]: response.data.result.desc,
        });
      });
      this.stopLoading();
    });
  };

  getLawServices = () => {
    axios.get("https://api.sr-cpa.co.il/getalllawservices").then((data) => {
      this.setState({
        lawsServices: data.data.result,
      });
    });
  };

  componentDidMount = () => {
    this.getLawServices();
    this.getServices();
    this.getAllReviewers();
    this.getAllTimeLines();
    strings.setLanguage(this.props.user.language);

    this.getContent(this.state.onGetAboutUs);
    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch((err) => {
        this.props.setContentEditor(false);
      });

    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true,
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false,
          });
      }
    };
    this.setState({
      workedYears: new Date().getFullYear() - 1986,
    });

    let currentHours = new Date().getHours();
    if (currentHours >= 7 && currentHours <= 12)
      this.setState({
        hello: strings.goodmorning,
      });
    else if (currentHours > 12 && currentHours < 20)
      this.setState({
        hello: strings.goodafternoon,
      });
    else
      this.setState({
        hello: strings.goodevening,
      });
  };

  removeService = (index, id) => {
    this.startLoading();
    axios
      .post("/deleteservice", {
        id,
        index,
      })
      .then((data) => {
        this.stopLoading();
        this.getServices();
      });
  };

  onSaveEditServiceTitle = (id, txt) => {
    this.startLoading();

    axios
      .post("/editservicetitle", {
        id,
        title: txt,
      })
      .then((data) => {
        if (data.status === 200) {
          this.props.toastManager.add("SUCCESS", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false,
          });
        }
        this.stopLoading();
        this.getServices();
      });
  };

  getServices = () => {
    axios.get("https://api.sr-cpa.co.il/getservices").then((data) => {
      if (data.status === 200)
        this.setState({
          servicesList: data.data.result,
        });
    });
  };

  scrollToElement = (el) => {
    scroller.scrollTo(el, {
      duration: 750,
      delay: 50,
      smooth: true,
      offset: -61, // Scrolls to element + 50 pixels down the page
    });
  };

  editTimeline = (id, desc, year) => {
    this.startLoading();

    axios
      .post("/edittimeline", {
        id,
        desc,
        year,
      })
      .then((data) => {
        if (data.data.updated) {
          this.props.toastManager.add("SUCCESS", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false,
          });
          this.getAllTimeLines();
        }
        this.stopLoading();
      });
  };

  editBossSection = (title, desc) => {
    this.startLoading();
    axios
      .all([
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: title.id,
          desc: title.title,
        }),
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: desc.id,
          desc: desc.title,
        }),
      ])
      .then((responses) => {
        this.stopLoading();

        const pid1 = "p" + title.id;
        const pid2 = "p" + desc.id;
        this.setState({
          [pid1]: title.title,
          [pid2]: desc.title,
        });

        this.props.toastManager.add("SUCCESS", {
          appearance: "success",
          autoDismiss: true,
          pauseOnHover: false,
        });
      });
  };

  editSectorsSedtion = (title, desc) => {
    this.startLoading();
    axios
      .all([
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: title.id,
          desc: title.title,
        }),
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: desc.id,
          desc: desc.title,
        }),
      ])
      .then((responses) => {
        this.stopLoading();

        this.props.toastManager.add("SUCCESS", {
          appearance: "success",
          autoDismiss: true,
          pauseOnHover: false,
        });
      });
  };

  render() {
    const { servicesList, allReviewers, allTimelines, lawsServices } =
      this.state;

    let allTimelinesArr = [];
    let lawsServicesArr = [];
    if (lawsServices.length) {
      lawsServices.map((service, index) => {
        lawsServicesArr.push(
          <div>
            <WorkingField
              icon={service.icon}
              title={service.title}
              desc={service.desc}
            />
          </div>
        );
      });
    }

    if (allTimelines.length) {
      allTimelines.map((timeline, index) => {
        allTimelinesArr.push(
          <div key={index}>
            {this.state.editingTimeline && this.props.user.contentEditor ? (
              <div
                style={{
                  paddingBottom: 10,
                  display: "grid",
                  gridTemplateColumns: "30px 30px",
                }}
              >
                <EditComp
                  mode={this.state.editingTimeline}
                  onClick={() =>
                    this.setState({
                      editingForTimeline: null,
                      editingTimeline: false,
                    })
                  }
                />
                <SaveComp
                  onClick={() => {
                    this.editTimeline(
                      timeline._id,
                      this.state.timeLineDescInput,
                      this.state.timeLineYearInput
                    );
                    this.setState({
                      editingForTimeline: null,
                      editingTimeline: false,
                    });
                  }}
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <div
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <EditComp
                    mode={this.state.editingTimeline}
                    onClick={() =>
                      this.setState({
                        editingForTimeline: timeline._id,
                        editingTimeline: true,
                        timeLineDescInput: timeline.desc,
                        timeLineYearInput: timeline.year,
                      })
                    }
                  />
                </div>
              )
            )}
            <TimelineItem
              bodyContainerStyle={{
                background:
                  'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399220/1_a0z3ic.jpg")',
                padding: 10,
                borderRadius: 2,
                boxShadow: "1px 2px 10px 0 rgba(0, 0, 0, 0.2)",
              }}
              key={index}
              dateComponent={
                index % 2 === 0 ? (
                  <DateComponent
                    setDateInput={(val) =>
                      this.setState({
                        timeLineYearInput: val,
                      })
                    }
                    background="#C2B59B"
                    mode={this.state.editingForTimeline === timeline._id}
                    title={timeline.year}
                  />
                ) : (
                  <DateComponent
                    setDateInput={(val) =>
                      this.setState({
                        timeLineYearInput: val,
                      })
                    }
                    mode={this.state.editingForTimeline === timeline._id}
                    title={timeline.year}
                  />
                )
              }
              style={{ color: "#e86971" }}
            >
              {this.state.editingForTimeline === timeline._id ? (
                <h3
                  contentEditable="plaintext-only"
                  suppressContentEditableWarning
                  onInput={({ target }) =>
                    this.setState({
                      timeLineDescInput: target.innerText,
                    })
                  }
                  style={{
                    textAlign: "right",
                    border: "1px black solid",
                  }}
                >
                  <span> {timeline.desc} </span>
                </h3>
              ) : (
                <h3 style={{ textAlign: "justify" }}>{timeline.desc}</h3>
              )}
            </TimelineItem>
          </div>
        );
      });
    }
    let allReviewersArr = [];
    if (allReviewers.length) {
      allReviewers.map((reviewer, index) => {
        allReviewersArr.push(
          <div key={index}>
            {this.state.editReviewer && this.props.user.contentEditor ? (
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "fit-content",
                  display: "grid",
                  direction: "rtl",
                  gridTemplateColumns: "30px 30px",
                }}
              >
                <EditComp
                  mode={this.state.editReviewer}
                  onClick={() =>
                    this.setState({
                      editReviewer: false,
                      editingReviewer: null,
                    })
                  }
                />
                <SaveComp
                  onClick={() => {
                    this.editReviewer(
                      reviewer._id,
                      this.state.editingReviewerName,
                      this.state.editingReviewerDesc
                    );
                    this.setState({
                      editReviewer: false,
                      editingReviewer: null,
                    });
                  }}
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <div
                  style={{
                    width: "fit-content",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <EditComp
                    mode={this.state.editReviewer}
                    onClick={() =>
                      this.setState({
                        editReviewer: true,
                        editingReviewer: reviewer._id,
                        editingReviewerName: reviewer.name,
                        editingReviewerDesc: reviewer.desc,
                      })
                    }
                  />
                </div>
              )
            )}
            <Reviewer
              setDescInput={(val) =>
                this.setState({
                  editingReviewerDesc: val,
                })
              }
              setNameInput={(val) =>
                this.setState({
                  editingReviewerName: val,
                })
              }
              mode={this.state.editingReviewer === reviewer._id}
              desc={reviewer.desc}
              name={reviewer.name}
              src={reviewer.img}
            />
          </div>
        );
      });
    }
    let servicesListArr = [];
    if (servicesList.length) {
      servicesList.map((service, index) => {
        let servicesAdv = [];
        service.services.map((desc) => {
          servicesAdv.push({
            url: "/home",
            desc,
          });
        });

        servicesListArr.push(
          <div key={index} style={{ position: "relative" }}>
            {this.state.serviceEdit && this.props.user.contentEditor ? (
              <div
                style={{
                  zIndex: 55,
                  position: "absolute",
                  right: 10,
                  top: 10,
                  display: "grid",
                  gridTemplateColumns: "30px 30px",
                }}
              >
                <EditComp
                  mode={this.state.serviceEdit}
                  noMarginLeft
                  onClick={() =>
                    this.setState({
                      serviceEdit: false,
                      serviceEditingFor: null,
                    })
                  }
                />
                <SaveComp
                  noMarginLeft
                  onClick={() => {
                    this.onSaveEditServiceTitle(
                      service._id,
                      this.state.serviceTitleInput
                    );
                    this.setState({
                      serviceEdit: false,
                      serviceEditingFor: null,
                    });
                  }}
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <div
                  style={{
                    zIndex: 55,
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                >
                  <EditComp
                    mode={this.state.serviceEdit}
                    noMarginLeft
                    onClick={() =>
                      this.setState({
                        serviceEdit: true,
                        serviceEditingFor: service._id,
                        serviceTitleInput: service.title,
                      })
                    }
                  />
                </div>
              )
            )}
            <Service
              newService={(value) =>
                this.setState({
                  newServiceInput: value,
                })
              }
              addService={this.addService}
              removeService={(index) => this.removeService(index, service._id)}
              onInput={(val) => {
                this.setState({
                  serviceTitleInput: val,
                });
              }}
              editing={this.state.serviceEditingFor === service._id}
              links={servicesAdv}
              title={service.title}
              icon={service.icon}
            />
          </div>
        );
      });
    }

    const settings = {
      autoplay: true,
      dots: true,
      infinite: true,
      arrows: false,
      speed: 1500,
      slidesToShow: 3,
      swipeable: true,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const settings1 = {
      autoplay: true,
      dots: false,
      infinite: true,
      arrows: false,
      speed: 1500,
      slidesToShow: 5,
      swipeable: true,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <EnglishBtn
          restart={() => {
            setTimeout(() => {
              this.setState({});
              this.forceUpdate();
            }, 200);

            alert(this.props.user.language);
          }}
        />
        <div
          id="headContainer"
          style={{
            overflow: "hidden",
            paddingBottom: 10,
            position: "relative",
          }}
        >
          <div
            className="imagebgContainer"
            style={{
              position: "absolute",
              overflow: "hidden",
              left: 0,
              zIndex: -1,
            }}
          >
            <div className="imgbg">
              <img src="https://res.cloudinary.com/tatwertech/image/upload/v1564343902/pexels-photo-938965222_2_1_lt0bjq.jpg" />
            </div>
          </div>
          <div className="height35VH">
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <TopAnnouncementBar auth={this.props.user.contentEditor} />
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>

          <div>
            <div
              className={
                strings.getLanguage() === "en"
                  ? "XOXOContainerEN"
                  : "XOXOContainer"
              }
            >
              <div
                className={strings.getLanguage() === "en" ? "XOXOEN" : "XOXO"}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <h1
                      style={{
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                        maxWidth: 450,
                      }}
                    >
                      {strings.samirozanco}
                    </h1>
                    <h1 style={{ marginTop: 15, maxWidth: 450 }}>
                      {strings.weareheros}
                      <br />
                      {strings.withnorelation}
                    </h1>

                    <h3
                      style={{
                        marginTop: 25,
                        maxWidth: 450,
                        fontWeight: "normal",
                        letterSpacing: "1.3px",
                        textAlign: "justify",
                        fontSize: "1.25rem",
                      }}
                    >
                      {strings.srisofficeof}
                    </h3>
                    <a
                      href="https://api.whatsapp.com/send?phone=972046112525&text=&source=&data="
                      target="_blank"
                      rel="noreferrer"
                      style={{ width: "fit-content" }}
                    >
                      <h3
                        style={{
                          fontSize: "1.4rem",
                          cursor: "pointer",
                          width: "fit-content",
                          display: "inline",
                        }}
                      >
                        <i
                          className="fab fa-whatsapp"
                          style={{
                            paddingTop: 15,
                            fontSize: "1.75rem",
                            marginTop: "auto",
                            marginBottom: "auto",
                            color: "green",
                          }}
                        />
                        <span style={{ paddingTop: 15, paddingRight: 10 }}>
                          {strings.talkstousinwhatsapp}
                        </span>
                      </h3>
                    </a>

                    <GreyButton
                      icon="arrow"
                      style={{
                        marginTop: 25,
                        display: "block",
                        padding: "7px 40px",
                        fontSize: "1.4rem",
                        marginBottom: 25,
                      }}
                      onPress={() => this.scrollToElement("Services")}
                      grey2
                      title={strings.officeservices}
                    />
                  </div>
                  <a className="fas fa-arrow-left moving" />
                </div>
              </div>
              <div
                onClick={() => this.scrollToElement("Audits")}
                style={{ textAlign: "center", marginTop: "auto" }}
              >
                <i className="fas fa-angle-down arrowmov" />
              </div>
              <div>
                <Board />
              </div>
            </div>
          </div>
        </div>

        <>
          <Element name="Audits">
            <div
              className={strings.getLanguage() === "en" ? "AuditsEN" : "Audits"}
            >
              <div
                className={
                  strings.getLanguage() === "en"
                    ? "marginRightEN"
                    : "marginRight"
                }
              >
                <h1 className="titleTeam">
                  {this.props.user.contentEditor && this.state.editAboutUs ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 30px",
                        marginRight: "auto",
                      }}
                    >
                      <EditComp
                        mode={this.state.editAboutUs}
                        onClick={() =>
                          this.setState({
                            editAboutUs: false,
                          })
                        }
                      />
                      <SaveComp
                        onClick={() => {
                          this.onSaveChangeAboutUs();
                          this.setState({
                            editAboutUs: false,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    this.props.user.contentEditor &&
                    !this.state.editAboutUs && (
                      <EditComp
                        mode={this.state.editAboutUs}
                        onClick={() =>
                          this.setState({
                            editAboutUs: true,
                            aboutUsFirstPr:
                              this.state.p5d46b3fd1c9d440000b09495,
                            aboutUsFirstPr1:
                              this.state.p5d46bb361c9d440000b09496,
                          })
                        }
                      />
                    )
                  )}
                  <span
                    style={{
                      cursor: "pointer",
                      border: "1px transparent solid",
                    }}
                    onClick={() => this.props.history.push("/about")}
                  >
                    {strings.aboutus}
                  </span>
                </h1>
                <SubtitleLines />
                {this.state.editAboutUs ? (
                  <span
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      border: "1px black solid",
                      fontSize: "1.25rem",
                      display: "block",
                    }}
                    suppressContentEditableWarning
                    onInput={({ target }) =>
                      this.setState({
                        aboutUsFirstPr: target.innerText,
                      })
                    }
                    contentEditable="plaintext-only"
                  >
                    {this.state.p5d46b3fd1c9d440000b09495}
                  </span>
                ) : (
                  <p
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      fontSize: "1.25rem",
                      textAlign: "justify",
                      border: "1px transparent solid",
                    }}
                  >
                    {this.state.p5d46b3fd1c9d440000b09495}
                  </p>
                )}
                {this.state.editAboutUs ? (
                  <span
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      border: "1px black solid",
                      fontSize: "1.25rem",
                      display: "block",
                    }}
                    suppressContentEditableWarning
                    onInput={({ target }) =>
                      this.setState({
                        aboutUsFirstPr1: target.innerText,
                      })
                    }
                    contentEditable="plaintext-only"
                  >
                    <strong>{this.state.p5d46bb361c9d440000b09496}</strong>
                  </span>
                ) : (
                  <p
                    style={{
                      marginTop: 15,
                      fontSize: "1.25rem",
                      textAlign: "justify",
                      border: "1px transparent solid",
                    }}
                  >
                    <strong>{this.state.p5d46bb361c9d440000b09496}</strong>
                  </p>
                )}

                {this.state.editAboutUs ? (
                  <span
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      border: "1px black solid",
                      fontSize: "1.25rem",
                      display: "block",
                    }}
                    suppressContentEditableWarning
                    onInput={({ target }) =>
                      this.setState({
                        aboutUsFirstPr2: target.innerText,
                      })
                    }
                    contentEditable="plaintext-only"
                  >
                    {this.state.p5d46bb431c9d440000b09497}
                  </span>
                ) : (
                  <p
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      textAlign: "justify",
                      border: "1px transparent solid",
                      fontSize: "1.25rem",
                    }}
                  >
                    {this.state.p5d46bb431c9d440000b09497}
                  </p>
                )}

                <div
                  style={{ marginTop: 20, marginLeft: 15, marginBottom: 50 }}
                >
                  <Slider {...settings}>
                    <User
                      link="/about"
                      param="{סאמי מחאמיד}"
                      src="https://res.cloudinary.com/tatwertech/image/upload/v1564680017/sami_avatar_qk842l.jpg"
                      title={strings.SamiMahamid}
                      subtitle={strings.samidesc}
                      desc='בהערכות השווי שדודו לקח בהם חלק לקראת השקעות של קרן איתן ניכרת טביעת אצבעותיו- העבודה היסודית, המקצועיות, הפתרונות הפרקטיים שהופכים סוגיות גדולות לפתרונות אפשריים, הצוות המקצועי שמלווה אותו, הופכים את "המשרד הקטן" ל"משרד מוביל".'
                    />
                    <User
                      link="/about"
                      param="רוזאן מחאמיד"
                      src="https://res.cloudinary.com/tatwertech/image/upload/v1564678853/WhatsApp_Image_2019-08-01_at_18.49.27_nwiywi.jpg"
                      title={strings.RozanMahamid}
                      subtitle={strings.femaleAccountant}
                    />
                    <User
                      link="/about"
                      param="ג'ואד אגבאריה"
                      src="https://res.cloudinary.com/tatwertech/image/upload/v1564680012/avatar_zgw5cc.png"
                      title={strings.jawadegb}
                      subtitle={strings.maleAccountant}
                    />
                    {/* <User
                      src="https://res.cloudinary.com/tatwertech/image/upload/v1564680012/avatar_zgw5cc.png"
                      title="שריף אגבאריה"
                      subtitle="רואה חשבון"
                    /> */}
                  </Slider>
                </div>
              </div>
              <div
                className={
                  strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
                }
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 35px 1px",
                  width: "fit-content",
                  height: "fit-content",

                  position: "relative",
                }}
              >
                <section
                  style={{
                    background:
                      "url('https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg')",

                    zIndex: 2,
                  }}
                  id="contactFormSection"
                >
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <h2 style={{ marginTop: 10, marginBottom: 10 }}>
                      <ReactTypingEffect
                        className="inline"
                        text={this.state.hello} //text=["Hello.", "World!"]
                      />
                      ,
                      <i
                        style={{
                          fontSize: "1.5rem",
                          marginRight: strings.getLanguage() === "HE" && 5,
                          marginLeft: strings.getLanguage() === "en" && 5,
                        }}
                        className="far fa-smile"
                      />
                    </h2>
                    <h1
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      <i
                        className="fas fa-info"
                        style={{
                          paddingLeft: strings.getLanguage() === "HE" && 10,
                          paddingRight: strings.getLanguage() === "en" && 10,
                        }}
                      />
                      {strings.callusorleaveyourdetails}
                    </h1>
                  </div>
                  <div
                    className="contactForm"
                    style={{
                      display: "block",
                      paddingRight: strings.getLanguage() === "HE" && 0,
                      paddingLeft: strings.getLanguage() === "en" && 0,
                      padding: 10,
                    }}
                  >
                    <ContactUsFloating ltr={strings.getLanguage() === "en"} />
                  </div>
                </section>
                <div
                  style={{
                    background:
                      "url('https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg')",

                    direction: strings.getLanguage() === "HE" ? "rtl" : "ltr",
                  }}
                >
                  <div
                    style={{
                      padding: 15,
                    }}
                  >
                    <h1 style={{ textAlign: "center", letterSpacing: "1.2px" }}>
                      <ReactTypingEffect text={strings.nowonourwebsite} />
                    </h1>
                    <div style={{ marginTop: 25 }}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        <GreyButton
                          grey2
                          title={strings.customerlogin}
                          style={{
                            display: "block",
                            width: "90%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontSize: "1.2rem",
                          }}
                        />
                        <GreyButton
                          grey2
                          title={strings.documents}
                          onPress={() => this.props.history.push("/documents")}
                          style={{
                            display: "block",
                            width: "90%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontSize: "1.2rem",
                          }}
                        />
                      </div>
                      <WantedFormInPages
                        ltr={strings.getLanguage() === "en"}
                        style={{ width: "100%", boxShadow: "unset" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Element>
        </>

        <>
          <Element name="Services">
            <div>
              <div className="ServicesContainer">{servicesListArr}</div>
            </div>
          </Element>
        </>

        <>
          <div
            className="accountantContainer"
            style={{
              direction: strings.getLanguage() === "en" ? "ltr" : "rtl",
            }}
          >
            <div
              className={
                strings.getLanguage() === "en"
                  ? "marginRightEN marginBottom"
                  : "marginRight marginBottom"
              }
            >
              <div className="paddingTop">
                <h1
                  className="titleTeam"
                  style={{
                    direction: strings.getLanguage() === "en" ? "ltr" : "rtl",
                  }}
                >
                  {this.state.editingBossTitle &&
                  this.props.user.contentEditor ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 30px",
                        marginBottom: 10,
                      }}
                    >
                      <EditComp
                        mode={this.state.editingBossTitle}
                        onClick={() =>
                          this.setState({
                            editingBossTitle: false,
                          })
                        }
                      />
                      <SaveComp
                        onClick={() => {
                          this.editBossSection(
                            {
                              title: this.state.titleSectionBoss,
                              id: "5d46dc291c9d44000025f82f",
                            },
                            {
                              title: this.state.descSectionBoss,
                              id: "5d46dc591c9d44000025f831",
                            }
                          );
                          this.setState({
                            editingBossTitle: false,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    this.props.user.contentEditor && (
                      <div style={{ marginBottom: 10 }}>
                        <EditComp
                          mode={this.state.editingBossTitle}
                          onClick={() =>
                            this.setState({
                              editingBossTitle: true,
                              titleSectionBoss:
                                this.state.p5d46dc291c9d44000025f82f,
                              descSectionBoss:
                                this.state.p5d46dc591c9d44000025f831,
                            })
                          }
                        />
                      </div>
                    )
                  )}
                  {this.state.editingBossTitle ? (
                    <span
                      contentEditable="plaintext-only"
                      style={{ border: "1px black solid" }}
                      suppressContentEditableWarning
                      onInput={({ target }) =>
                        this.setState({
                          titleSectionBoss: target.innerText,
                        })
                      }
                    >
                      {this.state.p5d46dc291c9d44000025f82f}
                    </span>
                  ) : (
                    <span> {this.state.p5d46dc291c9d44000025f82f} </span>
                  )}
                  <i
                    style={{ marginRight: 5 }}
                    className="far fa-money-bill-alt"
                  />
                </h1>
                <SubtitleLines />
                {this.state.editingBossTitle ? (
                  <p
                    contentEditable="plaintext-only"
                    suppressContentEditableWarning
                    onInput={({ target }) =>
                      this.setState({
                        descSectionBoss: target.innerText,
                      })
                    }
                    className={
                      strings.getLanguage() === "en"
                        ? "marginLeftPxEN"
                        : "marginLeftPx"
                    }
                    style={{
                      marginTop: 25,
                      fontSize: "1.25rem",
                      border: "1px black solid",
                      paddingTop: 10,
                      textAlign:
                        strings.getLanguage() === "en" ? "left" : "right",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.p5d46dc591c9d44000025f831,
                    }}
                  />
                ) : (
                  <span>
                    <p
                      className={
                        strings.getLanguage() === "en"
                          ? "marginLeftPxEN"
                          : "marginLeftPx"
                      }
                      style={{
                        marginTop: 25,
                        fontSize: "1.25rem",

                        paddingTop: 10,
                        textAlign: "justify",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.p5d46dc591c9d44000025f831,
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
            <div
              className={
                strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
              }
              style={{
                marginTop: 15,

                boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 35px 1px",
                padding: 25,

                background:
                  "url('https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg')",
                marginBottom: 25,
              }}
            >
              <h1>{strings.ourclientsrecommending}</h1>
              <SubtitleLines ltr />

              <div
                className={
                  strings.getLanguage() === "en" ? "arrowsEN" : "arrows"
                }
                style={{ direction: "ltr", marginTop: 15 }}
              >
                <Carousel
                  infiniteLoop
                  selectedItem={this.state.selectedItem}
                  onClickItem={(prop) =>
                    this.setState({
                      selectedItem: prop,
                    })
                  }
                  showArrows={true}
                  showIndicators={false}
                  swipeable={false}
                  showStatus={false}
                  showThumbs={false}
                >
                  {allReviewersArr}
                </Carousel>
              </div>
            </div>
          </div>
        </>

        <>
          <Parallax
            bgImageStyle={{
              objectFit: "contain",
            }}
            bgImage="https://res.cloudinary.com/tatwertech/image/upload/v1564340822/water-597024_1280_1_bfcv2x.jpg"
            bgImageAlt="the cat"
            strength={500}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                position: "relative",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "black",
                  zIndex: 3,
                  opacity: 0.2,
                }}
              />
              <div className="countUpParallex">
                <div style={{ color: "white", margin: "auto" }}>
                  {this.state.workedYears && (
                    <VisibilitySensor
                      onChange={this.onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp
                        className="countupCount"
                        decimals={0}
                        start={0}
                        end={this.state.workedYears}
                        suffix=" +"
                        duration={this.state.duration}
                        ref={(countUp) => {
                          this.myCountUp = countUp;
                        }}
                      />
                    </VisibilitySensor>
                  )}
                  <h1 className="countUpTitle">{strings.yearsWorked}</h1>
                </div>
                <div
                  style={{ height: "25vh", background: "white", width: 2 }}
                />
                <div style={{ color: "white", margin: "auto" }}>
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{ top: 10 }}
                    delayedCall
                  >
                    <CountUp
                      className="countupCount"
                      decimals={0}
                      start={0}
                      end={256}
                      suffix=" +"
                      duration={this.state.duration1}
                      ref={(countUp) => {
                        this.myCountUp1 = countUp;
                      }}
                    />
                  </VisibilitySensor>
                  <h1 className="countUpTitle">{strings.customersloving}</h1>
                </div>
              </div>
            </div>
          </Parallax>
        </>
        <>
          <div
            className="lawContainer"
            style={{
              direction: strings.getLanguage() === "en" ? "ltr" : "rtl",
            }}
          >
            <div
              className={
                strings.getLanguage() === "en"
                  ? "lawSubContaineEN"
                  : "lawSubContainer"
              }
              style={{
                display: "flex",

                padding: 17,

                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: strings.getLanguage() === "en" ? "80%" : "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h1>
                  {strings.practiciesFields}
                  <span>
                    <i
                      style={{ marginRight: 5 }}
                      className="fas fa-balance-scale"
                    />
                  </span>
                </h1>
                <SubtitleLines />

                <div className="workingFieldsContainer">{lawsServicesArr}</div>
              </div>
            </div>
            <div style={{ maxWidth: "100%" }}>
              <div className="marginTopNak">
                {strings.getLanguage() === "en" ? (
                  <h1>
                    More than {this.state.workedYears} of years working in
                    accountancy field
                  </h1>
                ) : (
                  <h1>מעל {this.state.workedYears} שנות נסיון של מצוינות</h1>
                )}
                <SubtitleLines ltr />
              </div>
              <div
                className={
                  strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
                }
                style={{
                  marginTop: 15,
                  padding: 10,

                  boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.3)",
                  marginBottom: 15,
                  background:
                    'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
                }}
              >
                {allTimelinesArr.length && (
                  <Timeline lineColor={"#ddd"}>{allTimelinesArr}</Timeline>
                )}
              </div>
            </div>
          </div>
          <>
            <Parallax
              bgImageStyle={{
                objectFit: "contain",
              }}
              bgImage="https://res.cloudinary.com/tatwertech/image/upload/v1564345266/tel-aviv-2827030_1920_1_vr8rjf.jpg"
              bgImageAlt="the cat"
              strength={700}
            >
              <div className="parallaxSectorsContainer">
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "black",
                    zIndex: 3,
                    opacity: 0.2,
                  }}
                />
                <div className="paddingPx" style={{ zIndex: 5 }}>
                  <h1>
                    {this.state.editingSectors &&
                    this.props.user.contentEditor ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30px 30px",
                          marginBottom: 10,
                          direction: "rtl",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "fit-content",
                        }}
                      >
                        <EditComp
                          onClick={() =>
                            this.setState({
                              editingSectors: false,
                            })
                          }
                        />
                        <SaveComp
                          onClick={() =>
                            this.editSectorsSedtion(
                              {
                                title: this.state.sectorsTitle,
                                id: "5d471e2a1c9d440000b022f5",
                              },
                              {
                                title: this.state.sectorsDesc,
                                id: "5d471ecf1c9d440000b022f6",
                              }
                            )
                          }
                        />
                      </div>
                    ) : (
                      this.props.user.contentEditor && (
                        <div
                          style={{
                            marginBottom: 10,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "fit-content",
                          }}
                        >
                          <EditComp
                            mode={this.state.editingSectors}
                            onClick={() =>
                              this.setState({
                                editingSectors: true,
                                sectorsTitle:
                                  this.state.p5d471e2a1c9d440000b022f5,
                                sectorsDesc:
                                  this.state.p5d471ecf1c9d440000b022f6,
                              })
                            }
                          />
                        </div>
                      )
                    )}
                    {this.state.editingSectors ? (
                      <span
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning
                        onInput={({ target }) =>
                          this.setState({
                            sectorsTitle: target.innerText,
                          })
                        }
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d471e2a1c9d440000b022f5}
                      </span>
                    ) : (
                      <span> {this.state.p5d471e2a1c9d440000b022f5} </span>
                    )}
                  </h1>
                  {this.state.editingSectors ? (
                    <h2
                      suppressContentEditableWarning
                      contentEditable="plaintext-only"
                      onInput={({ target }) =>
                        this.setState({
                          sectorsDesc: target.innerText,
                        })
                      }
                      style={{ textAlign: "right", border: "1px black solid" }}
                    >
                      {this.state.p5d471ecf1c9d440000b022f6}
                    </h2>
                  ) : (
                    <h2 style={{ textAlign: "justify" }}>
                      <span> {this.state.p5d471ecf1c9d440000b022f6} </span>
                    </h2>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div className="sectorsContainer">
                      <Specialization
                        icon="flaticon-truck"
                        title={strings.importandexport}
                      />
                      <Specialization
                        icon="flaticon-paper"
                        title={strings.insurance}
                      />
                      <Specialization
                        icon="flaticon-monitor"
                        title={strings.hitech}
                      />
                      <Specialization
                        title={strings.NGO}
                        icon="fas fa-hand-holding-heart"
                      />
                      <Specialization
                        icon="flaticon-fork"
                        title={strings.food}
                      />
                      <Specialization
                        icon="flaticon-plane"
                        title={strings.tourism}
                      />
                      <Specialization
                        title={strings.otherworks}
                        icon="flaticon-scissor"
                      />
                      <Specialization
                        title={strings.realestate}
                        icon="flaticon-urban"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          </>

          <>
            <div
              style={{
                position: "relative",
                boxShadow: "0 4px 15px -2px black",
              }}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  zIndex: -1,
                  objectFit: "cover",
                }}
                src="https://res.cloudinary.com/tatwertech/image/upload/v1564348471/architecture-bahai-gardens-buildings-190339_1_1_g8izp8.jpg"
              />
              <div
                className="advantagesContainer"
                style={{
                  direction: strings.getLanguage() === "en" ? "ltr" : "rtl",
                }}
              >
                <div
                  className="advantagesSubContainer"
                  style={{
                    marginRight: strings.getLanguage() === "en" ? "0%" : "25%",
                    marginLeft: strings.getLanguage() === "en" ? "25%" : "0%",
                  }}
                >
                  {this.state.editWhyUs && this.props.user.contentEditor ? (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 30px",
                        marginBottom: 10,
                      }}
                    >
                      <EditComp
                        mode={this.state.editWhyUs}
                        onClick={() =>
                          this.setState({
                            editWhyUs: false,
                          })
                        }
                      />
                      <SaveComp
                        onClick={() => {
                          this.changeWhyUs(
                            {
                              title: this.state.whyUsTitle,
                              id: "5d471fe01c9d440000b022f7",
                            },
                            {
                              title: this.state.whyUsDesc,
                              id: "5d4720041c9d440000b022f8",
                            }
                          );
                          this.setState({
                            editWhyUs: false,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    this.props.user.contentEditor && (
                      <div style={{ marginBottom: 10 }}>
                        <EditComp
                          mode={this.state.editWhyUs}
                          onClick={() =>
                            this.setState({
                              editWhyUs: true,
                              whyUsDesc: this.state.p5d4720041c9d440000b022f8,
                              whyUsTitle: this.state.p5d471fe01c9d440000b022f7,
                            })
                          }
                        />
                      </div>
                    )
                  )}

                  {this.state.editWhyUs ? (
                    <h1
                      onInput={({ target }) =>
                        this.setState({
                          whyUsTitle: target.innerText,
                        })
                      }
                      suppressContentEditabeWarning
                      contentEditable="plaintext-only"
                      style={{
                        fontSize: "2.25rem",
                        marginBottom: 10,
                        border: "1px black solid",
                      }}
                    >
                      {this.state.p5d471fe01c9d440000b022f7}
                    </h1>
                  ) : (
                    <h1 style={{ fontSize: "2.25rem", marginBottom: 10 }}>
                      <span> {this.state.p5d471fe01c9d440000b022f7} </span>
                    </h1>
                  )}
                  {this.state.editWhyUs ? (
                    <p
                      onInput={({ target }) =>
                        this.setState({
                          whyUsDesc: target.innerText,
                        })
                      }
                      suppressContentEditableWarning
                      contentEditable="plaintext-only"
                      style={{
                        fontSize: "1.25rem",
                        textAlign: "right",
                        border: "1px black solid",
                      }}
                    >
                      {this.state.p5d4720041c9d440000b022f8}
                    </p>
                  ) : (
                    <p style={{ fontSize: "1.25rem", textAlign: "justify" }}>
                      <span> {this.state.p5d4720041c9d440000b022f8} </span>
                    </p>
                  )}
                  <div style={{ marginTop: 15 }}>
                    <AdvantageItem desc={strings.credibility} />
                    <AdvantageItem desc={strings.greatbusinessexperience} />
                    <AdvantageItem desc={strings.professional} />
                    <AdvantageItem desc={strings.highavaiability} />
                    <AdvantageItem desc="בניית אסטרטגיית מיסוי מותאמת אישית" />
                    <AdvantageItem
                      desc={
                        strings.getLanguage() === "en"
                          ? `More than ${this.state.workedYears} of years working in accountancy field`
                          : `מעל ${this.state.workedYears} שנות ניסיון`
                      }
                    />
                    <AdvantageItem desc={strings.businessorientation} />
                    <AdvantageItem desc={strings.goodknowledge} />
                    <AdvantageItem desc={strings.personalaccompany} />
                  </div>
                  <div
                    style={{
                      width: "fit-content",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <GreyButton
                      grey2
                      title={strings.togetacunsult}
                      style={{
                        marginTop: 15,
                        cursor: "crosshair",
                        fontSize: "1.5rem",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
        <BlogsCarousel />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withToastManager(HomePageAr)));
