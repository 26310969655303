import React from "react";
import "./Specialization.css";

const Specialization = props => {
  return (
    <div className="specializedItem">
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "black",
          zIndex: 3,
          opacity: 0.5
        }}
      />
      <div className="specializedItemImage" style={{ zIndex: 5 }}>
        {props.src ? (
          <img
            src={props.src}
            style={{
              height: 85,
              width: 100,
              objectFit: "cover"
            }}
          />
        ) : (
          <i
            className={props.icon}
            style={{
              height: 85,
              width: 100,

              fontSize: "4rem",
              color: "white"
            }}
          />
        )}
        <h2 style={{ color: "white", textAlign: "center", paddingBottom: 15 }}>
          {props.title}
        </h2>
      </div>
    </div>
  );
};

export default Specialization;
