import React, { Component } from "react";
import { render } from "react-dom";
import "./tictactoe.css";
import strings from "../../localization/Localization";
function Square(props) {
  const { value, draw, winner, type } = props;
  if (winner) {
    if (winner[0].includes(type) && value === "X") {
      return (
        <button
          className="square"
          aria-label="XO"
          type={type}
          onClick={props.onClick}
        >
          <i
            style={{
              color: "green"
            }}
            className="fas fa-times"
          />
        </button>
      );
    } else
      return (
        <button className="square" type={type} onClick={props.onClick}>
          {value === "X" ? (
            <i
              style={{
                color: "black"
              }}
              className="fas fa-times"
            />
          ) : value === "O" ? (
            <i className="far fa-circle" />
          ) : (
            <></>
          )}
        </button>
      );
  } else
    return (
      <button className="square" type={type} onClick={props.onClick}>
        {value === "X" ? (
          <i
            style={{
              color: winner
                ? winner.includes(type) !== -1
                  ? "green"
                  : "black"
                : "black"
            }}
            className="fas fa-times"
          />
        ) : value === "O" ? (
          <i
            style={{
              color: "black"
            }}
            className="far fa-circle"
          />
        ) : (
          <></>
        )}
      </button>
    );
}

export class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squares: Array(9).fill(null),
      xIsNext: TextTrackCueList,
      humanTurn: true,
      turn: 0
    };
  }

  randomIntFromInterval = (
    min,
    max // min and max included
  ) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  componentDidMount = () => {
    let { squares, xIsNext } = this.state;
    squares = Array(9).fill(null);
    squares = squares.slice();

    squares[0] = "X";

    this.setState({ squares: squares, xIsNext: !xIsNext, turn: 0 });
  };
  handleClick = i => {
    var { squares, xIsNext, turn } = this.state;
    squares = squares.slice();

    if (calculateWinner(squares) || calculateDraw(squares)) {
      squares = Array(9).fill(null);

      squares[0] = "X";
      this.setState({ squares: squares, xIsNext: false, turn: 0 });
      return;
    }
    if (calculateWinner(squares) || squares[i] || xIsNext) {
      return;
    }

    squares[i] = xIsNext ? "X" : "O";

    this.setState(
      {
        squares: squares,
        xIsNext: true,
        turn: turn + 1
      },
      () => {
        var squares = this.state.squares;
        setTimeout(() => {
          const lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
          ];

          if (squares[3] === squares[i] && turn === 0) {
            squares[4] = "X";
            this.setState({
              squares: squares,
              xIsNext: false,
              turn: turn + 1
            });
            return;
          }

          let winOrwin = false;
          for (let i = 0; i < lines.length; i++) {
            const [a, b, c] = lines[i];

            if (squares[a] && squares[a] === squares[b] && !squares[c]) {
              squares[c] = "X";
              winOrwin = true;
              this.setState(
                {
                  squares: squares,
                  xIsNext: false
                },
                () => {
                  setTimeout(() => {
                    calculateWinner(squares);
                  }, 0);
                }
              );
              return;
            } else if (squares[c] && !squares[b] && squares[a] === squares[c]) {
              squares[b] = "X";
              winOrwin = true;
              this.setState(
                {
                  squares: squares,
                  xIsNext: false
                },
                () => {
                  setTimeout(() => {
                    calculateWinner(squares);
                  }, 750);
                }
              );
              return;
            } else if (squares[b] && squares[b] === squares[c] && !squares[a]) {
              squares[a] = "X";
              winOrwin = true;
              this.setState(
                {
                  squares: squares,
                  xIsNext: false
                },
                () => {
                  setTimeout(() => {
                    calculateWinner(squares);
                  }, 750);
                }
              );
              return;
            }
            if (i === lines.length - 1 && !winOrwin) {
              let clicked = false;
              for (let j = 0; j < lines.length; j++) {
                if (squares[a] === "X" && !squares[b] && !squares[c]) {
                  squares[c] = "X";
                  this.setState({
                    squares,
                    xIsNext: false
                  });
                  clicked = true;
                  return;
                } else if (squares[b] === "X" && !squares[a] && !squares[c]) {
                  squares[a] = "X";
                  this.setState({
                    squares,
                    xIsNext: false
                  });
                  clicked = true;

                  return;
                } else if (squares[c] === "X" && !squares[a] && !squares[b]) {
                  squares[b] = "X";
                  this.setState({
                    squares,
                    xIsNext: false
                  });
                  clicked = true;

                  return;
                } else {
                  for (let i = 0; i < lines.length; i++) {
                    const [aa, bb, cc] = lines[i];
                    let newArrayOfNotWantedNums = [];
                    if (
                      this.state.squares[aa] &&
                      this.state.squares[bb] &&
                      this.state.squares[aa] !== this.state.squares[bb] &&
                      !this.state.squares[cc]
                    ) {
                      newArrayOfNotWantedNums.push(cc);
                    } else if (
                      this.state.squares[aa] &&
                      this.state.squares[cc] &&
                      this.state.squares[aa] !== this.state.squares[cc] &&
                      !this.state.squares[bb]
                    ) {
                      newArrayOfNotWantedNums.push(bb);
                    } else if (
                      this.state.squares[bb] &&
                      this.state.squares[cc] &&
                      this.state.squares[bb] !== this.state.squares[cc] &&
                      !this.state.squares[aa]
                    ) {
                      newArrayOfNotWantedNums.push(aa);
                    }
                    if (!winOrwin && !clicked) {
                      for (var empty = 0; empty < 9; empty++) {
                        if (
                          newArrayOfNotWantedNums.length &&
                          this.state.xIsNext
                        ) {
                          newArrayOfNotWantedNums.map(num => {
                            if (!squares[empty] && num !== empty) {
                              squares[empty] = "X";

                              this.setState({
                                squares,
                                xIsNext: false
                              });
                              return;
                            }
                          });
                        } else {
                          if (!squares[4] && this.state.xIsNext) {
                            squares[4] = "X";

                            this.setState({
                              squares,
                              xIsNext: false
                            });
                            return;
                          }
                          if (!squares[empty + 2] && this.state.xIsNext) {
                            squares[empty + 2] = "X";

                            this.setState({
                              squares,
                              xIsNext: false
                            });
                            return;
                          }
                          if (!squares[empty + 1] && this.state.xIsNext) {
                            squares[empty + 1] = "X";

                            this.setState({
                              squares,
                              xIsNext: false
                            });
                            return;
                          } else if (!squares[empty] && this.state.xIsNext) {
                            squares[empty] = "X";

                            this.setState({
                              squares,
                              xIsNext: false
                            });
                            return;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }, 500);
      }
    );
  };

  renderSquare(i, draw, winner) {
    const { squares, xIsNext } = this.state;
    return (
      <Square
        type={i}
        draw={draw}
        winner={winner}
        value={squares[i]}
        onClick={() => this.handleClick(i)}
      />
    );
  }

  render() {
    const { squares, xIsNext } = this.state;
    const winner = calculateWinner(squares);
    const draw = calculateDraw(squares);
    let status;
    if (winner) {
      if (winner[1] === "X") status = strings.wewon;
    } else {
      if (draw) status = strings.draw;
      else if (!this.state.xIsNext) status = strings.yourturn;
      else status = strings.ourturn;
    }

    return (
      <div
        style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
      >
        <div className="board-row">
          {this.renderSquare(0, draw, winner)}
          {this.renderSquare(1, draw, winner)}
          {this.renderSquare(2, draw, winner)}
        </div>
        <div className="board-row">
          {this.renderSquare(3, draw, winner)}
          {this.renderSquare(4, draw, winner)}
          {this.renderSquare(5, draw, winner)}
        </div>
        <div className="board-row">
          {this.renderSquare(6, draw, winner)}
          {this.renderSquare(7, draw, winner)}
          {this.renderSquare(8, draw, winner)}
        </div>

        <div className="status" style={{ marginTop: 20 }}>
          <h2
            style={{
              color: winner
                ? winner[1] === "X"
                  ? "green"
                  : "black"
                : draw
                ? "#C2B59B"
                : "black"
            }}
          >
            {status}
          </h2>
        </div>
      </div>
    );
  }
}

export class Game extends React.Component {
  render() {
    return (
      <div className="game" style={{ marginLeft: "auto", marginRight: "auto" }}>
        <div className="game-board">
          <Board />
        </div>
        <div className="game-info">
          <div>{/* status */}</div>
          <ol>{/* TODO */}</ol>
        </div>
      </div>
    );
  }
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return [[a, b, c], squares[a]];
    }
  }
  return null;
}

function calculateDraw(squares) {
  if (calculateWinner(squares)) return false;
  let Draw = true;
  for (var i = 0; i < 9; i++) {
    if (!squares[i]) Draw = false;
  }
  return Draw;
}
