import React, { Component } from "react";

const Reviewer = props => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          direction: "rtl"
        }}
      >
        <div className="reviewerImageContainer">
          <img
            style={{ objectFit: "cover", borderRadius: 3 }}
            src={props.src}
          />
        </div>
        <div
          style={{
            width: "100%",
            background:
              "url('https://res.cloudinary.com/tatwertech/image/upload/v1564399220/1_a0z3ic.jpg')",
            marginTop: 15,
            marginBottom: 15,

            borderRadius: 3
          }}
        >
          {props.mode ? (
            <>
              <p
                contentEditable
                suppressContentEditableWarning
                style={{
                  letterSpacing: "1.4px",
                  padding: 20,
                  textAlign: "right",
                  border: "1px black solid"
                }}
                onInput={({ target }) => props.setDescInput(target.innerText)}
              >
                {props.desc}
              </p>

              <p
                contentEditable
                suppressContentEditableWarning
                onInput={({ target }) => props.setNameInput(target.innerText)}
                style={{
                  textAlign: "right",
                  padding: 20,
                  border: "1px black solid",
                  fontWeight: "bold"
                }}
              >
                {props.name}
              </p>
            </>
          ) : (
            <>
              <p
                className="hey"
                style={{
                  letterSpacing: "1.4px",
                  padding: 20,
                  textAlign: "justify"
                }}
              >
                <span> {props.desc} </span>
              </p>
              <p
                style={{
                  textAlign: "right",
                  padding: 20,
                  fontWeight: "bold"
                }}
              >
                <span> {props.name} </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reviewer;
