import React from "react";
import "./EnglishBtn.css";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions/firstActions";
import { withRouter } from "react-router-dom";

import GreyButton from "../GreyButton/GreyButton";
import { Refresh } from "../..";

const EnglishBtn = props => {
  const reloadOrNavigate = () => {
    const { history, location } = props;
    if (location.pathname === "/") {
      history.replace(`/reload`);
      setTimeout(() => {
        history.replace(`/`);
      });
    } else {
      history.push("/");
    }
  };
  return (
    <>
      <div
        className="englishBtn"
        style={{
          position: "fixed",

          left: -48,
          zIndex: 99999,
          bottom: 130,
          transform: "rotate(-90deg)"
        }}
      >
        <GreyButton
          style={{
            borderRadius: 0,
            background: "black",
            display: "flex",
            padding: "8px 20px"
          }}
          black
          onPress={() => {
            props.setLanguage(props.user.language === "HE" ? "EN" : "HE");
            reloadOrNavigate();
          }}
          title={props.user.language === "HE" ? "English" : "עברית"}
          icon="fas fa-globe-americas"
        />
      </div>
    </>
  );
};
const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLanguage: str => {
      dispatch(setLanguage(str));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EnglishBtn));
