import React, { Component } from "react";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import NavBar from "../../components/navBar/navBar";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import "./ResourcesPage.css";
import DocumentItem from "../../components/DocumentItem/DocumentItem";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";

import { setContentEditor } from "../../redux/actions/firstActions";

import { withToastManager } from "react-toast-notifications";
import EditComp from "../../components/EditComp/EditComp";
import SaveComp from "../../components/SaveComp/SaveComp";
import GreyButton from "../../components/GreyButton/GreyButton";
import { stringify } from "querystring";
import strings from "../../localization/Localization";
import LoadingOverlay from "react-loading-overlay";

var Scroll = require("react-scroll");

var Element = Scroll.Element;
var scroller = Scroll.scroller;

class ResourcesPage extends Component {
  state = {
    kishorim: [],
    calculators: [],
    linkDesc: "",
    linkTitle: "",
    calcDesc: "",
    calcLink: "",
    isActive: false,
    calcTitle: "",
    linklink: ""
  };
  setNavbarActivated = bool =>
    this.setState({
      activated: bool
    });
  getcalculators = () => {
    this.setState({
      isActive: true
    });
    fetch("/getallcalculators")
      .then(res => res.json())
      .then(data => {
        if (data) {
          this.setState({
            isActive: false
          });
          this.setState({
            calculators: data.result
          });
        }
      });
  };

  getkishorim = () => {
    this.setState({
      isActive: true
    });
    fetch("/getallkishorim")
      .then(res => res.json())
      .then(data => {
        if (data) {
          this.setState({
            isActive: false
          });
          this.setState({
            kishorim: data.result
          });
        }
      });
  };
  componentDidMount = () => {
    this.getkishorim();
    this.getcalculators();
    fetch("/checkauth")
      .then(res => res.json())
      .then(data => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch(err => {
        this.props.setContentEditor(false);
      });

    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false
          });
      }
    };
  };
  scrollToElement = el => {
    scroller.scrollTo(el, {
      duration: 750,
      delay: 50,
      smooth: true,
      offset: -61
    });
  };
  componentWillReceiveProps = props => {
    if (props.location.state)
      if (props.location.state.param !== this.state.param) {
        this.setState({
          param: props.location.state.param
        });
        setTimeout(() => {
          this.scrollToElement(props.location.state.param);
        }, 500);
      }
  };
  closeModal = () =>
    this.setState({
      visible: false
    });
  openModal = () =>
    this.setState({
      visible: false
    });
  render() {
    const { kishorim, calculators } = this.state;

    let kishorimArr = [];
    if (kishorim.length) {
      kishorim.map((kishor, index) => {
        kishorimArr.push(
          <div key={index}>
            <DocumentItem
              link={kishor.link}
              title={kishor.title}
              icon={kishor.icon}
              desc={kishor.desc}
            />
          </div>
        );
      });
    }

    let calculatorsarr = [];
    if (calculators.length) {
      calculators.map((calc, index) => {
        calculatorsarr.push(
          <div key={index}>
            <DocumentItem
              link={calc.link}
              title={calc.title}
              icon={calc.icon}
              desc={calc.desc}
            />
          </div>
        );
      });
    }
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <Modal
          visible={this.state.visible}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          {this.state.modalContent}
        </Modal>
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative"
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>
        <ImageBanner
          title={strings.links}
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />
        <div
          style={{
            paddingTop: 25,

            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "resourcesContainerEN"
                : "resourcesContainer"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>
            <div>
              <div
                className="marginLeft5Pr marginRight5Pr"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <Element name="קישורים">
                  {this.props.user.contentEditor && (
                    <i
                      onClick={() => {
                        this.setState({
                          visible: true,
                          modalContent: (
                            <div
                              style={{
                                padding: 25,
                                minHeight: 200,
                                minWidth: 200,
                                textAlign: "center"
                              }}
                            >
                              <h1>הוספת קישור חדש</h1>
                              <div
                                style={{
                                  marginTop: 15,
                                  width: "100%",
                                  display: "grid",
                                  gridTemplateColumns: "40% 60%",
                                  direction:
                                    strings.getLanguage() === "en"
                                      ? "ltr"
                                      : "rtl"
                                }}
                              >
                                <label>שם קישור</label>
                                <input
                                  onChange={({ target }) => {
                                    this.setState({
                                      linkTitle: target.value
                                    });
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  marginTop: 15,
                                  width: "100%",
                                  display: "grid",
                                  gridTemplateColumns: "40% 60%",
                                  direction: "rtl"
                                }}
                              >
                                <label>לינק קישור</label>
                                <input
                                  onChange={({ target }) =>
                                    this.setState({
                                      linklink: target.value
                                    })
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  marginTop: 15,
                                  width: "100%",
                                  display: "grid",
                                  gridTemplateColumns: "40% 60%",
                                  direction: "rtl"
                                }}
                              >
                                <label>תיאור</label>
                                <input
                                  onChange={({ target }) =>
                                    this.setState({
                                      linkDesc: target.value
                                    })
                                  }
                                />
                              </div>
                              <GreyButton
                                style={{ fontSize: "1.1rem", marginTop: 20 }}
                                purple
                                onPress={() => {
                                  this.closeModal();
                                  this.setState({
                                    isActive: true
                                  });
                                  fetch("/addkishor", {
                                    method: "POST",
                                    body: JSON.stringify({
                                      title: this.state.linkTitle,
                                      desc: this.state.linkDesc,
                                      link: this.state.linklink
                                    }),
                                    headers: {
                                      "Content-Type": "application/json"
                                    }
                                  })
                                    .then(res => res.json())
                                    .then(data => {
                                      this.getkishorim();
                                      if (data.added) {
                                        this.setState({
                                          isActive: false
                                        });
                                      }
                                    });
                                }}
                                title="הוסף"
                              />
                            </div>
                          )
                        });
                      }}
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        color: "green"
                      }}
                      className="fas fa-plus"
                    />
                  )}

                  <h1 style={{ textAlign: "center", letterSpacing: "1.35px" }}>
                    {strings.usefullinks}
                  </h1>
                </Element>
                <SubtitleLines
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                />
              </div>
              <div className="marginLeft linksContainer marginRight5Pr">
                {kishorimArr}
              </div>
              <div
                className="marginLeft5Pr marginRight5Pr"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                {this.props.user.contentEditor && (
                  <i
                    onClick={() => {
                      this.setState({
                        visible: true,
                        modalContent: (
                          <div
                            style={{
                              padding: 25,
                              minHeight: 200,
                              minWidth: 200,
                              textAlign: "center"
                            }}
                          >
                            <h1>הוספת מחשבון חדש</h1>
                            <div
                              style={{
                                marginTop: 15,
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "40% 60%",
                                direction: "rtl"
                              }}
                            >
                              <label>שם מחשבון</label>
                              <input
                                onChange={({ target }) =>
                                  this.setState({
                                    calcTitle: target.value
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "40% 60%",
                                direction: "rtl"
                              }}
                            >
                              <label>לינק מחשבון</label>
                              <input
                                onChange={({ target }) =>
                                  this.setState({
                                    calcLink: target.value
                                  })
                                }
                              />
                            </div>
                            <div
                              style={{
                                marginTop: 15,
                                width: "100%",
                                display: "grid",
                                gridTemplateColumns: "40% 60%",
                                direction: "rtl"
                              }}
                            >
                              <label>תיאור</label>
                              <input
                                onChange={({ target }) =>
                                  this.setState({
                                    calcDesc: target.value
                                  })
                                }
                              />
                            </div>
                            <GreyButton
                              style={{ fontSize: "1.1rem", marginTop: 20 }}
                              purple
                              onPress={() => {
                                this.closeModal();
                                this.setState({
                                  isActive: true
                                });
                                fetch("/addcalculator", {
                                  method: "POST",
                                  body: JSON.stringify({
                                    title: this.state.calcTitle,
                                    desc: this.state.calcDesc,
                                    link: this.state.calcLink
                                  }),
                                  headers: {
                                    "Content-Type": "application/json"
                                  }
                                })
                                  .then(res => res.json())
                                  .then(data => {
                                    if (data.added) {
                                      this.getcalculators();
                                      this.setState({
                                        isActive: false
                                      });
                                    }
                                  });
                              }}
                              title="הוסף"
                            />
                          </div>
                        )
                      });
                    }}
                    style={{
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      color: "green"
                    }}
                    className="fas fa-plus"
                  />
                )}
                <Element name="מחשבונים">
                  <h1 style={{ textAlign: "center", letterSpacing: "1.35px" }}>
                    {strings.calculators}
                  </h1>
                </Element>
                <SubtitleLines
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                />
              </div>
              <div className="marginLeft linksContainer marginRight5Pr">
                {calculatorsarr}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContentEditor: bool => {
      dispatch(setContentEditor(bool));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(ResourcesPage));
