import React, { PureComponent, Component } from "react";
import ReactDOM from "react-dom";
import "./publicStyles/index.css";
import "./publicStyles/slick.css";
import "./publicStyles/slickTheme.css";
import "./publicStyles/fonts/flaticon.css";
import Access from "./publicStyles/Access";
import ResourcesPage from "./containers/ResourcesPage/ResourcesPage";
import { Provider } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import {
  ToastProvider,
  DefaultToast,
  DefaultToastContainer
} from "react-toast-notifications";

import store from "./store";
import ScrollUpButton from "react-scroll-up-button";

// Layouts/Containers
// import HomePageEn from "./containers/homePage/homePage";
import HomePageAr from "./containers/homePage/HomePageAr";
import Page404NotFound from "./containers/404Page/404Page";
import AboutUsPage from "./containers/AboutUsPage/AboutUsPage";
import Panel from "./containers/Panel/Panel";
import PanelLogin from "./containers/PanelLogin/PanelLogin";
import ContactUsPage from "./containers/ContactUsPage/ContactUsPage";
import LawServicesPage from "./containers/LawServicesPage/LawServicesPage";
import AccountingServicesPage from "./containers/AccountingServicesPage/AccountingServicesPage";
import Favicon from "react-favicon";

// Static Components
import NavBar from "./components/navBar/navBar";
import Footer from "./components/footer/footer";
import DocumentsPage from "./containers/DocumentsPage/DocumentsPage";
import BlogsPage from "./containers/BlogsPage/BlogsPage";
import BlogPage from "./containers/BlogPage/BlogPage";

const AboutUsPageAr = () => <AboutUsPage Ar />;

class ScrollIntoView1 extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = prevProps => {
    if (this.props.location.pathname !== prevProps.location.pathname)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
  };

  render = () => this.props.children;
}

const ScrollIntoView = withRouter(ScrollIntoView1);

const scrollButton = () => {
  return (
    <ScrollUpButton
      ContainerClassName="AnyClassForContainer"
      TransitionClassName="AnyClassForTransition"
      EasingType="easeInCirc"
      ShowAtPosition={150}
    />
  );
};

export const Refresh = ({ path = "/" }) => (
  <Route
    path={path}
    component={({ history, location, match }) => {
      history.replace({
        ...location,
        pathname: location.pathname.substring(match.path.length)
      });
      return null;
    }}
  />
);

const MyCustomToastContainer = ({ children, ...props }) => (
  <DefaultToastContainer style={{ zIndex: 992592 }} {...props}>
    {children}
  </DefaultToastContainer>
);

const MyCustomToast = ({ children, ...props }) => (
  <div style={{ overflow: "hidden", direction: "rtl" }}>
    <DefaultToast {...props}>{children}</DefaultToast>
  </div>
);

class Accessibility extends Component {
  componentDidMount = () => {
    const script = document.createElement("script");

    script.innerHTML = Access;
    script.async = true;
    const script2 = document.createElement("script");
    script2.async = true;

    script2.innerHTML = `   
    window.onload = function() {
      window.micAccessTool = new MicAccessTool({
        forceLang: "he-IL",
        buttonPosition: "right",
        link: "https://acctoolbar.ml",
        contact: "mailto:mickidum@gmail.com"
      });
    };
   `;
    const script3 = document.createElement("script");
    script3.async = true;
    script3.src =
      "https://platform-api.sharethis.com/js/sharethis.js#property=5d40094a003c1b0012ebc70e&product=sticky-share-buttons";

    document.body.appendChild(script);
    document.body.appendChild(script3);
    document.body.appendChild(script2);
  };
  render() {
    return <> </>;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Favicon url="https://res.cloudinary.com/tatwertech/image/upload/v1564590115/faviconsamirozanco_piqqpx.ico" />

    <ToastProvider
      components={{
        Toast: MyCustomToast,
        ToastContainer: MyCustomToastContainer
      }}
      placement="bottom-center"
    >
      <Router>
        <ScrollIntoView>
          <Route component={scrollButton} />

          <Switch>
            <Route path="/" exact component={HomePageAr} />
            <Route path="/about" component={AboutUsPage} />
            <Route
              path="/reload"
              component={() => (
                <div
                  style={{
                    height: "100vh",
                    width: "100vh",
                    background: "white"
                  }}
                >
                  <LoadingOverlay active spinner text="Loading.." />
                </div>
              )}
            />
            <Route path="/lawservices" component={LawServicesPage} />
            <Route
              path="/accountingservices"
              component={AccountingServicesPage}
            />
            <Refresh path={`/refresh/${window.location.pathname}`} />
            <Route path="/contact" component={ContactUsPage} />
            <Route path="/login" component={PanelLogin} />
            <Route path="/panel" component={Panel} />
            <Route path="/resources" component={ResourcesPage} />
            <Route path="/documents" component={DocumentsPage} />
            <Route path="/articles" component={BlogsPage} />
            <Route path="/article" component={BlogPage} />

            <Route component={Page404NotFound} />
          </Switch>
          <Route
            render={routeProps => {
              return (
                <>
                  <Footer {...routeProps} Ar />
                  <Accessibility />
                </>
              );
            }}
          />
        </ScrollIntoView>
      </Router>
    </ToastProvider>
  </Provider>,
  document.getElementById("root")
);

// {/* <Route path="/en" exact component={HomePageEn} />
// <Route path="/about/en" exact component={AboutUsPage} /> */}
//
