import React from "react";
import "./ContactFormInPages.css";
import GreyButton from "../GreyButton/GreyButton";
import strings from "../../localization/Localization";

const ContactFormInPages = props => {
  return (
    <div
      style={{
        width: props.noPadding && "100%",
        boxShadow: props.noPadding && "unset",
        padding: props.noPadding && 0
      }}
      className={
        strings.getLanguage() === "en"
          ? "aboutUsSubContainerEN"
          : "aboutUsSubContainer"
      }
    >
      {!props.noForm && (
        <>
          <h2 style={{ textAlign: "center" }}>{strings.leaveyourdetails}</h2>
          <hr
            style={{
              width: "25%",
              background: "black",
              height: 3,
              margin: "10px auto"
            }}
          />

          <form
            style={{
              width: 280,
              marginTop: 10,
              marginLeft: "auto",
              marginRight: "auto"
            }}
            autoComplete="off"
            action="https://formspree.io/info@sr-cpa.co.il"
            method="POST"
          >
            <input
              style={{
                display: "block",
                width: "100%",
                border: "2px black solid",
                background: "transparent",
                padding: 10,
                color: "black",
                fontSize: "1rem",
                marginBottom: 10
              }}
              name={strings.fullname}
              placeholder={strings.fullname}
            />
            <input
              style={{
                display: "block",
                width: "100%",
                border: "2px black solid",
                background: "transparent",
                padding: 10,
                color: "black",
                fontSize: "1rem",
                marginBottom: 10
              }}
              name={strings.phone}
              placeholder={strings.phone}
            />
            <input
              style={{
                display: "block",
                width: "100%",
                border: "2px black solid",
                background: "transparent",
                padding: 10,
                color: "black",
                fontSize: "1rem",
                marginBottom: 10
              }}
              name={strings.emailaddress}
              placeholder={strings.emailaddress}
            />
            <textarea
              style={{
                display: "block",
                width: "100%",
                resize: "none",
                height: 60,

                border: "2px black solid",
                background: "transparent",
                padding: 10,
                color: "black",
                fontSize: "1rem",
                marginBottom: 10
              }}
              name={strings.message}
              placeholder={strings.message}
            />

            <GreyButton
              purple
              title={strings.send}
              style={{
                width: "100%",
                outline: "none",
                color: "white",
                background: "black",
                borderRadius: 0,
                padding: "5px 40px",
                fontSize: "1.25rem"
              }}
            />
          </form>
        </>
      )}
      <div style={{ marginTop: 15 }}>
        <h2 style={{ textAlign: "center" }}>{strings.visitandseehello}</h2>
        <hr
          style={{
            width: "25%",
            background: "black",
            height: 3,
            margin: "10px auto"
          }}
        />
        <p style={{ marginTop: 15, fontSize: "1.25rem" }}>
          משרד ראשי א.א.פחם, כביש ראשי צמוד לסלקום, מיקוד 3001000
        </p>
        <p style={{ marginTop: 15, fontSize: "1.25rem" }}>
          <span>
            <i
              style={{ fontSize: "1.25rem", paddingLeft: 5 }}
              className="fas fa-phone"
            />
          </span>
          <span>טלפון: 04-6112525</span>
        </p>
      </div>
    </div>
  );
};

export default ContactFormInPages;
