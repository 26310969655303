import React from "react";
import "./footer.css";
import SocialMediaIcons from "react-social-media-icons";
import ScrollAnimation from "react-animate-on-scroll";
import GreyButton from "../GreyButton/GreyButton";
import { connect } from "react-redux";
import strings from "../../localization/Localization";

const socialMediaIcons = [
  {
    url: "https://gitlab.com/andrewgbliss",
    className: "fa-instagram"
  },
  {
    url: "https://www.npmjs.com/~andrewgbliss",
    className: "fa-facebook"
  }
];

const HalfIcon = props => (
  <div
    className="halfIcon"
    style={{
      height: 80,
      width: 80,
      objectFit: "cover",
      position: "absolute",
      top: -40,
      left: 0,
      boxShadow: "1px white",
      background: "rgb(194, 181, 155, 0.9)",
      right: 0,
      marginLeft: "auto",
      marginRight: "auto"
    }}
  >
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <i
        className={props.icon}
        style={{
          color: "white",
          marginBottom: "auto",
          fontSize: "2rem",
          marginTop: "auto"
        }}
      />
    </div>
  </div>
);

const Footer = props => {
  strings.setLanguage(props.user.language);
  return (
    <footer
      style={{
        direction: "rtl",
        background:
          'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
      }}
    >
      <div className="footer-up">
        <div
          style={{
            color: "white",
            textAlign: "center",
            position: "relative"
          }}
        >
          <HalfIcon icon="far fa-clock" />

          <div className="marginTop65">
            <h1 style={{ color: "white", textAlign: "center" }}>
              {strings.hoursopened}
            </h1>
            <hr
              style={{
                width: "10%",
                marginTop: 15,
                marginLeft: "auto",
                marginRight: "auto",
                background: "#818285"
              }}
            />
            <div style={{ marginTop: 25 }}>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.SundayToThursday}
              </h3>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.Satudrday}
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{
            color: "white",
            textAlign: "center",
            position: "relative"
          }}
        >
          <HalfIcon icon="fas fa-phone" />
          <div style={{ marginTop: 65 }}>
            <h1 style={{ color: "white", textAlign: "center" }}>
              {strings.waystocontact}
            </h1>
            <hr
              style={{
                width: "10%",
                marginTop: 15,
                marginLeft: "auto",
                marginRight: "auto",
                background: "#818285"
              }}
            />
            <div style={{ marginTop: 25 }}>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.landlinephone}
              </h3>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.fax}
              </h3>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.samiofficemanager}
              </h3>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                {strings.ktovet}
              </h3>
              <h3
                style={{
                  color: "white",
                  textAlign: "center",
                  padding: 5,
                  fontWeight: "normal"
                }}
              >
                info@sr-cpa.co.il
              </h3>
            </div>
          </div>
        </div>
        <div
          style={{
            color: "white",

            position: "relative"
          }}
        >
          <HalfIcon icon="fas fa-hand-holding-heart" />
          <div
            style={{
              marginTop: 65,
              direction: strings.getLanguage() === "en" ? "ltr" : "rtl",
              marginLeft: "auto",
              width: 280,
              marginRight: "auto",
              display: "block"
            }}
          >
            <h2>{strings.leaveyourdetails}</h2>
            <form
              style={{
                width: 280,
                marginTop: 10,
                marginLeft: "auto",
                marginRight: "auto"
              }}
              action="https://formspree.io/info@sr-cpa.co.il"
              method="POST"
            >
              <input
                style={{
                  display: "block",
                  width: "100%",
                  border: "2px white solid",
                  background: "transparent",
                  padding: 10,
                  color: "white",
                  fontSize: "1rem",
                  marginBottom: 10
                }}
                name={strings.fullname}
                placeholder={strings.fullname}
              />
              <input
                style={{
                  display: "block",
                  width: "100%",
                  border: "2px white solid",
                  background: "transparent",
                  padding: 10,
                  color: "white",
                  fontSize: "1rem",
                  marginBottom: 10
                }}
                name={strings.phone}
                placeholder={strings.phone}
              />
              <input
                style={{
                  display: "block",
                  width: "100%",
                  border: "2px white solid",
                  background: "transparent",
                  padding: 10,
                  color: "white",
                  fontSize: "1rem",
                  marginBottom: 10
                }}
                name={strings.emailaddress}
                placeholder={strings.emailaddress}
              />
              <GreyButton
                purple
                title={strings.send}
                style={{
                  width: "100%",
                  outline: "none",
                  color: "black",
                  background: "white",
                  borderRadius: 0,
                  padding: "5px 40px",
                  fontSize: "1.25rem"
                }}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <h2>{strings.copyrights}</h2>
        {strings.getLanguage() === "HE" ? (
          <h2>
            האתר נבנה ע"י תטווירטק <span className="dontShowOnMobile">|</span>
            <span style={{ color: "#6980d3", marginLeft: 5 }}>Tatwer</span>
            <span style={{ color: "#dd6ccc", marginRight: 5 }}>tech</span>
            <span style={{ color: "#312D2C" }}>בניית אתרים לעסקים</span>
          </h2>
        ) : (
          <h2>
            <span>This Website was Developed By</span>
            <span style={{ color: "#6980d3", marginLeft: 5 }}>Tatwer</span>
            <span style={{ color: "#dd6ccc", marginRight: 5 }}>tech</span>

            <span>| Businesses Websites Developing</span>
          </h2>
        )}
      </div>
      <div
        className="wazeContainer"
        style={{ position: "relative", minHeight: 300, overflow: "hidden" }}
      >
        <iframe
          title="סאמי רוזאן ושות' - משרד רואי חשבון"
          src="https://embed.waze.com/he/iframe?zoom=14&lat=32.522639&lon=35.155239&ct=livemap&pin=1"
          width="600"
          height="450"
          style={{
            position: "absolute",
            width: "100%",

            top: 0,
            left: 0
          }}
        />
      </div>
    </footer>
  );
};

const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

export default connect(mapStateToProps)(Footer);
