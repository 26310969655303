import React, { Component } from "react";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import NavBar from "../../components/navBar/navBar";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import "./DocumentsPage.css";
import DocumentItem from "../../components/DocumentItem/DocumentItem";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import strings from "../../localization/Localization";
import Modal from "react-awesome-modal";
import GreyButton from "../../components/GreyButton/GreyButton";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { withToastManager } from "react-toast-notifications";

import { connect } from "react-redux";
import { setContentEditor } from "../../redux/actions/firstActions";

class DocumentsPage extends Component {
  state = {
    modalContent: <></>,
    tofesMaams: [],
    tofesMas: [],
    linkTitle: "",
    linkDesc: "",

    tofesPetoah: []
  };
  setNavbarActivated = bool =>
    this.setState({
      activated: bool
    });

  getAllTofesMas = () => {
    fetch("/getalltofesmas")
      .then(res => res.json())
      .then(data => {
        if (data) {
          this.setState({
            tofesMas: data.result
          });
        }
      });
  };

  getAllTofesPetoah = () => {
    fetch("/getalltofespetoah")
      .then(res => res.json())
      .then(data => {
        if (data) {
          this.setState({
            tofesPetoah: data.result
          });
        }
      });
  };

  getAllTofesMaam = () => {
    fetch("/getalltofesmaam")
      .then(res => res.json())
      .then(data => {
        if (data) {
          this.setState({
            tofesMaams: data.result
          });
        }
      });
  };
  componentDidMount = () => {
    this.getAllTofesMaam();
    this.getAllTofesPetoah();
    this.getAllTofesMas();
    fetch("/checkauth")
      .then(res => res.json())
      .then(data => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch(err => {
        this.props.setContentEditor(false);
      });
    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false
          });
      }
    };
  };

  closeModal = () =>
    this.setState({
      visible: false
    });

  deleteTofes = id => {
    fetch("/deletetofes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.deleted) {
          this.props.toastManager.add("SUCCESS", {
            appearance: "success",
            autoDismiss: true,
            pauseOnHover: false
          });
          this.getAllTofesMaam();
          this.getAllTofesPetoah();
          this.getAllTofesMas();
        } else {
          this.props.toastManager.add("error", {
            appearance: "danger",
            autoDismiss: true,
            pauseOnHover: false
          });
        }
      })
      .catch(err =>
        this.props.toastManager.add("error", {
          appearance: "danger",
          autoDismiss: true,
          pauseOnHover: false
        })
      );
  };

  openModal = () =>
    this.setState({
      visible: true
    });
  render() {
    const { tofesMaams, tofesMas, tofesPetoah } = this.state;

    let tofesMaamsArr = [],
      tofesMasArr = [],
      tofesPetoahArr = [];
    if (tofesPetoah.length) {
      tofesPetoah.map((tofes, index) => {
        tofesPetoahArr.push(
          <div key={index}>
            {this.props.user.contentEditor && (
              <h3
                onClick={() => this.deleteTofes(tofes._id)}
                style={{ color: "red", textAlign: "center", cursor: "pointer" }}
              >
                X
              </h3>
            )}
            <DocumentItem
              link={tofes.link}
              title={tofes.title}
              icon={tofes.icon}
              desc={tofes.desc}
            />
          </div>
        );
      });
    }
    if (tofesMaams.length) {
      tofesMaams.map((tofes, index) => {
        tofesMaamsArr.push(
          <div key={index}>
            {this.props.user.contentEditor && (
              <h3
                onClick={() => this.deleteTofes(tofes._id)}
                style={{ color: "red", textAlign: "center", cursor: "pointer" }}
              >
                X
              </h3>
            )}
            <DocumentItem
              link={tofes.link}
              title={tofes.title}
              icon={tofes.icon}
              desc={tofes.desc}
            />
          </div>
        );
      });
    }
    if (tofesMas.length) {
      tofesMas.map((tofes, index) => {
        tofesMasArr.push(
          <div key={index}>
            {this.props.user.contentEditor && (
              <h3
                onClick={() => this.deleteTofes(tofes._id)}
                style={{ color: "red", textAlign: "center", cursor: "pointer" }}
              >
                X
              </h3>
            )}
            <DocumentItem
              link={tofes.link}
              title={tofes.title}
              icon={tofes.icon}
              desc={tofes.desc}
            />
          </div>
        );
      });
    }
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <Modal
          visible={this.state.visible}
          effect="fadeInUp"
          onClickAway={() => this.closeModal()}
        >
          {this.state.modalContent}
        </Modal>
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative"
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>
        <ImageBanner
          title={strings.documents}
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />
        <div
          style={{
            paddingTop: 25,
            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")'
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "documentsContainerEN"
                : "documentsContainer"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>
            <div>
              <div
                className="marginLeft5Pr marginRight5Pr"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    letterSpacing: "1.35px"
                  }}
                >
                  טפסי מע"מ
                </h1>
                <SubtitleLines
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                />
              </div>
              {this.props.user.contentEditor && (
                <i
                  style={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    color: "green"
                  }}
                  className="fas fa-plus"
                  onClick={() => {
                    this.setState({
                      visible: true,
                      modalContent: (
                        <div
                          style={{
                            padding: 25,
                            minHeight: 200,
                            minWidth: 200,
                            textAlign: "center"
                          }}
                        >
                          <h1>הוספת טופס מע"ם חדש</h1>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction:
                                strings.getLanguage() === "en" ? "ltr" : "rtl"
                            }}
                          >
                            <label>שם טופס</label>
                            <input
                              onChange={({ target }) => {
                                this.setState({
                                  linkTitle: target.value
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "block",

                              direction: "rtl"
                            }}
                          >
                            <input
                              type="file"
                              name="file"
                              onChange={e => {
                                this.setState({
                                  file: e.target.files[0]
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction: "rtl"
                            }}
                          >
                            <label>תיאור</label>
                            <input
                              onChange={({ target }) =>
                                this.setState({
                                  linkDesc: target.value
                                })
                              }
                            />
                          </div>
                          <GreyButton
                            style={{ fontSize: "1.1rem", marginTop: 20 }}
                            purple
                            onPress={() => {
                              this.closeModal();
                              this.setState({
                                isActive: true
                              });
                              const fd = new FormData();
                              fd.append("file", this.state.file);
                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data"
                                }
                              };

                              axios
                                .post("/upload", fd, config)
                                .then(response => {
                                  fetch("/addtofesmaam", {
                                    method: "POST",
                                    body: JSON.stringify({
                                      title: this.state.linkTitle,
                                      desc: this.state.linkDesc,
                                      link: response.data.src
                                    }),
                                    headers: {
                                      "Content-Type": "application/json"
                                    }
                                  })
                                    .then(res => res.json())
                                    .then(data => {
                                      this.getAllTofesMaam();
                                      if (data.added) {
                                        this.setState({
                                          isActive: false
                                        });
                                      }
                                    })
                                    .catch(err => console.log(err));
                                })
                                .catch(err => console.log(err));
                            }}
                            title="הוסף"
                          />
                        </div>
                      )
                    });
                  }}
                />
              )}
              <div className="marginLeft linksContainer marginRight5Pr">
                {tofesMaamsArr}
              </div>
              <div
                className="marginLeft5Pr marginRight5Pr"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <h1 style={{ textAlign: "center", letterSpacing: "1.35px" }}>
                  טפסי מס הכנסה
                </h1>
                <SubtitleLines
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                />
              </div>
              {this.props.user.contentEditor && (
                <i
                  style={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    color: "green"
                  }}
                  className="fas fa-plus"
                  onClick={() => {
                    this.setState({
                      visible: true,
                      modalContent: (
                        <div
                          style={{
                            padding: 25,
                            minHeight: 200,
                            minWidth: 200,
                            textAlign: "center"
                          }}
                        >
                          <h1>הוספת טופס מס הכנסה חדש</h1>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction:
                                strings.getLanguage() === "en" ? "ltr" : "rtl"
                            }}
                          >
                            <label>שם טופס</label>
                            <input
                              onChange={({ target }) => {
                                this.setState({
                                  linkTitle: target.value
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "block",

                              direction: "rtl"
                            }}
                          >
                            <input
                              type="file"
                              name="file"
                              onChange={e => {
                                this.setState({
                                  file: e.target.files[0]
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction: "rtl"
                            }}
                          >
                            <label>תיאור</label>
                            <input
                              onChange={({ target }) =>
                                this.setState({
                                  linkDesc: target.value
                                })
                              }
                            />
                          </div>
                          <GreyButton
                            style={{ fontSize: "1.1rem", marginTop: 20 }}
                            purple
                            onPress={() => {
                              this.closeModal();
                              this.setState({
                                isActive: true
                              });
                              const fd = new FormData();
                              fd.append("file", this.state.file);
                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data"
                                }
                              };

                              axios
                                .post("/upload", fd, config)
                                .then(response => {
                                  fetch("/addtofesmas", {
                                    method: "POST",
                                    body: JSON.stringify({
                                      title: this.state.linkTitle,
                                      desc: this.state.linkDesc,
                                      link: response.data.src
                                    }),
                                    headers: {
                                      "Content-Type": "application/json"
                                    }
                                  })
                                    .then(res => res.json())
                                    .then(data => {
                                      this.getAllTofesMaam();
                                      if (data.added) {
                                        this.setState({
                                          isActive: false
                                        });
                                      }
                                    })
                                    .catch(err => console.log(err));
                                })
                                .catch(err => console.log(err));
                            }}
                            title="הוסף"
                          />
                        </div>
                      )
                    });
                  }}
                />
              )}
              <div className="marginLeft linksContainer marginRight5Pr">
                {tofesMasArr}
              </div>
              <div
                className="marginLeft5Pr marginRight5Pr"
                style={{ marginTop: 5, marginBottom: 5 }}
              >
                <h1 style={{ textAlign: "center", letterSpacing: "1.35px" }}>
                  טפסי ביטוח לאומי
                </h1>
                <SubtitleLines
                  style={{ marginRight: "auto", marginLeft: "auto" }}
                />
              </div>
              {this.props.user.contentEditor && (
                <i
                  style={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    color: "green"
                  }}
                  className="fas fa-plus"
                  onClick={() => {
                    this.setState({
                      visible: true,
                      modalContent: (
                        <div
                          style={{
                            padding: 25,
                            minHeight: 200,
                            minWidth: 200,
                            textAlign: "center"
                          }}
                        >
                          <h1>הוספת טופס ביטוח לאומי חדש</h1>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction:
                                strings.getLanguage() === "en" ? "ltr" : "rtl"
                            }}
                          >
                            <label>שם טופס</label>
                            <input
                              onChange={({ target }) => {
                                this.setState({
                                  linkTitle: target.value
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "block",

                              direction: "rtl"
                            }}
                          >
                            <input
                              type="file"
                              name="file"
                              onChange={e => {
                                this.setState({
                                  file: e.target.files[0]
                                });
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                              direction: "rtl"
                            }}
                          >
                            <label>תיאור</label>
                            <input
                              onChange={({ target }) =>
                                this.setState({
                                  linkDesc: target.value
                                })
                              }
                            />
                          </div>
                          <GreyButton
                            style={{ fontSize: "1.1rem", marginTop: 20 }}
                            purple
                            onPress={() => {
                              this.closeModal();
                              this.setState({
                                isActive: true
                              });
                              const fd = new FormData();
                              fd.append("file", this.state.file);
                              const config = {
                                headers: {
                                  "content-type": "multipart/form-data"
                                }
                              };

                              axios
                                .post("/upload", fd, config)
                                .then(response => {
                                  fetch("/addtofespetoah", {
                                    method: "POST",
                                    body: JSON.stringify({
                                      title: this.state.linkTitle,
                                      desc: this.state.linkDesc,
                                      link: response.data.src
                                    }),
                                    headers: {
                                      "Content-Type": "application/json"
                                    }
                                  })
                                    .then(res => res.json())
                                    .then(data => {
                                      this.getAllTofesMaam();
                                      if (data.added) {
                                        this.setState({
                                          isActive: false
                                        });
                                      }
                                    })
                                    .catch(err => console.log(err));
                                })
                                .catch(err => console.log(err));
                            }}
                            title="הוסף"
                          />
                        </div>
                      )
                    });
                  }}
                />
              )}
              <div className="marginLeft linksContainer marginRight5Pr">
                {tofesPetoahArr}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setContentEditor: bool => {
      dispatch(setContentEditor(bool));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.firstReducer
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(DocumentsPage));
