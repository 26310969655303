import React, { Component } from "react";
import SubtitleLines from "../../SubtitleLines/SubtitleLines";
import BlogItem from "../../BlogItem/BlogItem";
import Slider from "react-slick";
import axios from "axios";

class BlogsCarousel extends Component {
  state = {
    articlesList: [],
  };
  randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  componentDidMount = () => {
    axios.get("https://api.sr-cpa.co.il/getallarticles").then((data) => {
      if (data.status === 200) {
        this.setState({
          articlesList: data.data.result,
        });
      }
    });
  };
  render() {
    const { articlesList } = this.state;
    let articlesListArr = [];
    if (articlesList.length) {
      articlesList.map((article, index) => {
        articlesListArr.push(
          <div key={index}>
            <BlogItem
              id={article._id}
              time={this.randomIntFromInterval(3, 7).toString()}
              title={article.title}
              src={article.src}
            />
          </div>
        );
      });
    } else return <></>;

    const settings1 = {
      autoplay: true,
      dots: false,
      infinite: true,
      arrows: false,
      speed: 1500,
      slidesToShow: 5,
      swipeable: true,
      slidesToScroll: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <div
          style={{
            paddingBottom: 70,
            paddingLeft: 40,
            paddingTop: 25,
            paddingRight: 40,
            background:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <h1
            style={{
              textAlign: "center",
              marginBottom: 10,
              letterSpacing: 1.4,
            }}
          >
            טיפים ועדכונים חשובים
          </h1>

          <SubtitleLines
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 25,
            }}
          />
          <h3
            style={{
              marginBottom: 50,
              textAlign: "center",
              fontWeight: "normal",
            }}
          >
            כתבות, מידע שימושי ועדכונים חמים מרוכזים עבורך
          </h3>
          <Slider {...settings1}>{articlesListArr}</Slider>
        </div>
      </>
    );
  }
}

export default BlogsCarousel;
