import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    talkstousinwhatsapp: "Talk to us on WhatsApp",
    weareheros: "We are champions at XO, try us",
    withnorelation: "Regardless of that, We are great with cases and numbers",
    samirozanco: "Sami Rozan & Co | Accountants Office",
    yourturn: "Your turn",
    ourturn: "Our turn",
    officeservices: "Our office services",
    wewon: "We won again!",
    contactus: "Contact us",
    draw: "Draw",
    usefulinfo: "Useful information",
    goodmorning: "Good morning",
    goodafternoon: "Good afternoon",
    goodevening: "Nice evening",
    about: "About",
    customerlogin: "Clients Login",
    aboutus: "About us",
    accounting: "Accounting",
    Financialmanagement: "Financial Management",
    homepage: "Homepage",
    legalservices: "Legal Services - Lawyer",
    send: "Send",

    firstname: "Firstname",
    lastname: "Lastname",
    fullname: "Fullname",
    message: "Message",
    phone: "Phone number",
    SamiMahamid: "Sami Mahamid",
    samidesc: "Founder, Accountant and Lawyer",
    emailaddress: "Email address",
    maleAccountant: "Accountant",
    femaleAccountant: "Accountant",
    RozanMahamid: "Rozan Mahamid",
    documents: "Documents",
    jawadegb: "Jawad Egbaria",
    yearsWorked: "Years in business",
    importandexport: "Import & Export",
    customersloving: "Happy Clients",
    hitech: "Hi-Tech",
    NGO: "NGO's",
    food: "Food",
    tourism: "Tourism",
    otherworks: "Other industries",
    SundayToThursday: "Sunday - Thursday 8.30 to 16.00",
    landlinephone: "Landline: +97261112525",
    hoursopened: "Service Hours",
    insurance: "Insurance",
    waystocontact: "Get in Touch",
    fax: "Fax: +97246310265",
    ktovet: "Mail Address: Umm Alfahem P.O.B 4211 ZIP 3001000",
    samiofficemanager: "Sami - Office Manager: +972522515623",
    realestate: "Real Estate & Construction",
    copyrights: "SR Sami Rozan & Co - Accountant Office. All Rights Reserved.",
    leaveyourdetails: "Drop us a line",
    calculators: "Calculators",
    Satudrday: "Saturday - 10.00 to 19.00",
    wanteddesc:
      "Are you looking for a future with a more stable professional career? Send us your portfolio today!",
    wanted: "Wanted",
    manageledgeraccounts: "Manage ledger accounts",
    accountancy: "Accountancy",
    Taxcounseling: "Tax counseling",
    FinancialManagementReport: "Financial management report",
    payroll: "Payroll",
    Financialstatement: "Financial statement",
    cashflowmanagement: "Cash flow management",
    makingabusinessplan: "Making a business plan",
    Reportingtotaxauthorities: "Reporting to tax authorities",
    annualreportsforbusinesses: "Annual reports for businesses",
    financialreports: "Financial reports",
    insurancelaws: "Insurance laws",
    career: "Career",
    laborlaws: "Labor and employment laws",
    links: "Links",
    Aboutsamirozan: "About Sami Rozan Co",
    we: "About us",
    classsuits: "Presenting suits",
    retirementplanning: "Retirement planning",
    ourclientsrecommending: "Our clients recommending",
    articles: "Articles",
    companieslaws: "Companies laws",
    professional: "Professionalism",
    taxlaws: "Tax laws",
    highavaiability: "High availability",
    nowonourwebsite: "Now on our website",
    visitandseehello: "Visit us and say hello",
    realestatelaws: "Accompanying real estate transactions",
    localauthorities: "Local authorities",
    usefullinks: "Useful links",
    businessorientation: "Business orientation",
    togetacunsult: "To get consult, call now 04-6112525",
    practiciesFields: `Practicies fields - Lawyer Sami Mahamid`,
    goodknowledge: "Comprehensive and in-depth knowledge",
    credibility: "Credibility",
    personalaccompany: "Personal accompaniment",
    greatbusinessexperience: "Rich business experience",
    callusorleaveyourdetails:
      "Call us now on +9726112525 or leave your details and we will get back to you as soon as possible",
    srisofficeof: `SR is the commercial name of Sami Rozan & Co. accounting firm, one of Israel's leading accounting firms providing variety of services such as: accounting, auditing, tax consulting, economic consulting and strategic consulting.`
  },
  HE: {
    annualreportsforbusinesses: 'דו"חות שנתיים לעסקים',
    taxlaws: "דיני מסים",
    visitandseehello: "קפצו לבקר",
    usefullinks: "קישורים שימושיים",
    practiciesFields: "תחומי התמחות - עורך דין סאמי מחאמיד",
    nowonourwebsite: "עכשיו באתר",
    personalaccompany: "ליווי אישי",
    businessorientation: "אוריינטציה עסקית",
    togetacunsult: "לפגישת ייעוץ לעסק שלך חייג 04-6112525",
    credibility: "אמינות",
    goodknowledge: "ידע מקיף ומעמיק",
    highavaiability: "זמינות גבוהה",
    professional: "מקצועיות",
    greatbusinessexperience: "ניסיון עסקי עשיר",
    classsuits: "תביעות ייצוגיות",
    ourclientsrecommending: "לקוחות שלנו ממליצים",
    laborlaws: "דיני עבודה",
    realestatelaws: "ליווי עסקאות נדל”ן",
    companieslaws: "דיני חברות",
    retirementplanning: "תכנון פרישה לפנסיה",
    Financialmanagement: "ניהול כספים",
    cashflowmanagement: "ניהול תזרים מזומנים",
    links: "קישורים",
    articles: "מאמרים וכתבות",
    localauthorities: "רשויות מקומיות",
    calculators: "מחשבונים",
    insurancelaws: "דיני ביטוח לאומי",
    we: "אנחנו",
    career: "קריירה",
    Aboutsamirozan: "'אודות סאמי רוזאן ושות",
    makingabusinessplan: "בניית תכנית עסקית",
    FinancialManagementReport: `דו"ח ניהולי כספי`,
    Financialstatement: "הצהרת הון",
    payroll: "חשבות שכר",
    Reportingtotaxauthorities: "דיווח וייצוג בפני רשויות המס",
    wanteddesc: 'האם אתם מחפשים עתיד עם קריירה מקצועית יותר? שלחו קו"ח היום!',
    wanted: "דרושים",
    Taxcounseling: "ייעוץ מס",
    manageledgeraccounts: "ניהול פנקסי חשבונות",
    accountancy: "הנהלת חשבונות",
    financialreports: 'דו"חות כספיים',
    maleAccountant: "רואה חשבון",
    copyrights: "כל הזכויות שמורות לסאמי רוזאן ושות' | רואי חשבון",
    samiofficemanager: "סאמי - מנהל המשרד : 052-2515623",
    landlinephone: "טל: 04-6112525",
    fax: "פקס: 04-6310265",
    insurance: "ביטוח",
    tourism: "תיירות",
    ktovet: "כתובת: ת.ד. 4211 - א.א.פחם, מיקוד 3001000",
    food: "מזון",
    realestate: 'נדל"ן וחברות בניה',
    SundayToThursday: `ימים א' - ה' 8:30 - 16:00`,
    Satudrday: `שבת 10:00 - 19:00`,
    otherworks: "מקצועות חופשיים",
    hitech: "הייטק",
    NGO: "עמותות",
    jawadegb: "ג'ואד אגבאריה",
    yearsWorked: "שנות וותק",
    customersloving: "לקוחות מרוצים",
    documents: "טפסים",
    importandexport: "יבוא ויצוא",
    femaleAccountant: "רואת חשבון",
    RozanMahamid: "רוזאן מחאמיד",
    legalservices: "שירותים משפטיים - עורך דין",
    samidesc: "מייסד, רואה חשבון ועורך דין",
    withnorelation: " ובלי קשר, מעולים עם תיקים ומספרים.",
    weareheros: "אנחנו אלופים באיקס עיגול, נסו אותנו",
    samirozanco: `סאמי רוזאן ושות' | משרד רואי חשבון`,
    SamiMahamid: "סאמי מחאמיד",
    srisofficeof: `SR הינו שמו המסחרי של משרד רואי חשבון סאמי רוזאן ושות'
    הנמנה בין המשרדים הבינוניים והמובילים בישראל למתן שירותי
    ראיית חשבון: חשבונאות, ביקורת, ייעוץ מס, ייעוץ כלכלי
    וייעוץ אסטרטגי.`,
    accounting: "ראיית חשבון",
    goodevening: "ערב טוב",
    goodmorning: "בוקר טוב",
    phone: "מספר טלפון",
    emailaddress: "דואר אלקטרוני",
    goodafternoon: "צהריים טובים",
    talkstousinwhatsapp: "דברו איתנו בWhatsApp",
    homepage: "דף הבית",
    officeservices: "שירותי המשרד",
    yourturn: "התור שלך עכשיו",
    wewon: "שוב, נצחנו",
    firstname: "שם פרטי",
    lastname: "שם משפחה",
    draw: "תיקו",
    message: "הודעה",
    ourturn: "התור שלנו",
    aboutus: "אודות המשרד",
    about: "אודות",
    customerlogin: "כניסה ללקוחות",
    contactus: "צרו קשר",
    moneymanagement: "ניהול כספים",
    usefulinfo: "מידע שימושי",
    callusorleaveyourdetails: `לקבלת ייעוץ ללא תשלום וללא התחייבות חייגו
    04-6112525 
    או השאירו פרטים ונחזור אליכם בהקדם`,
    waystocontact: "דרכי יצירת קשר",
    leaveyourdetails: "השאירו פרטים ונחזור אליכם..",
    hoursopened: "שעות פעילות המשרד",
    fullname: "שם מלא",
    send: "שלח/י"
  }
});

export default strings;
