export const setMobile = bool => {
  return {
    type: "MOBILE",
    payload: bool
  };
};

export const setContentEditor = bool => {
  return {
    type: "SETCONTENTEDITOR",
    payload: bool
  };
};

export const setLanguage = str => {
  return {
    type: "SETLANGUAGE",
    payload: str
  };
};
