import React from "react";
import "./WorkingField.css";

const WorkingField = props => {
  let newDesc =
    props.desc.length > 150 ? props.desc.substr(0, 150) + "..." : props.desc;
  return (
    <div
      className="shown-par"
      style={{ marginLeft: 15, marginRight: 15, marginBottom: 15 }}
    >
      <i
        style={{ fontSize: "6rem", color: "#AD7F57" }}
        className={props.icon}
      />
      <h3
        style={{
          textAlign: "center",
          marginBottom: 10,
          minHeight: 45,
          marginTop: 5
        }}
      >
        {props.title}
      </h3>

      <p
        className="hidden-par"
        style={{ textAlign: "justify" }}
        dangerouslySetInnerHTML={{ __html: newDesc }}
      />
    </div>
  );
};

export default WorkingField;
