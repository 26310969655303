import React from "react";
import "./DocumentItem.css";

const DocumentItem = props => {
  return (
    <a
      className="documentItem"
      target="_blank"
      href={props.link}
      rel="noreferrer"
    >
      <i
        style={{
          color: "#A97A4F",
          fontSize: "2.75rem",
          display: "block",
          marginLeft: "auto",

          marginRight: "auto",
          width: "fit-content"
        }}
        className={props.icon}
      />
      <h2 style={{ marginTop: 10, textAlign: "center" }}>{props.title}</h2>
      <p style={{ marginTop: 10, fontSize: "1.15rem" }}>{props.desc}</p>
    </a>
  );
};

export default DocumentItem;
