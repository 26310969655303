import React, { Component } from "react";
import { withRouter } from "react-router";
import GreyButton from "../../components/GreyButton/GreyButton";
import NavBar from "../../components/navBar/navBar";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import { connect } from "react-redux";
import { setContentEditor } from "../../redux/actions/firstActions";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import TeamPlayer from "../../components/TeamPlayer/TeamPlayer";
import "./AboutUsPage.css";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import axios from "axios";
import EditComp from "../../components/EditComp/EditComp";
import SaveComp from "../../components/SaveComp/SaveComp";
import LoadingOverlay from "react-loading-overlay";
import { withToastManager } from "react-toast-notifications";
import strings from "../../localization/Localization";

var Scroll = require("react-scroll");

var Element = Scroll.Element;
var scroller = Scroll.scroller;

class AboutUsPage extends Component {
  state = {
    teamPlayersList: [],
    param: null,
    isActive: false,
    onGetAboutUs: [
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d47460e1c9d440000b02305",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d47469b1c9d440000b02306",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4746cc1c9d440000b02307",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4746ff1c9d440000b02308",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4747481c9d440000b02309",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4747dd1c9d440000b0230a",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4748391c9d440000b0230b",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4748821c9d440000b0230c",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4749501c9d440000b0230e",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d4749a01c9d440000b0230f",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474cc61c9d440000b02310",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474d041c9d440000b02311",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474d931c9d440000b02315",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474d7a1c9d440000b02314",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474d5c1c9d440000b02313",
      }),
      axios.post("https://api.sr-cpa.co.il/getcertainparagraph", {
        id: "5d474d431c9d440000b02312",
      }),
    ],
  };

  setNavbarActivated = (bool) =>
    this.setState({
      activated: bool,
    });

  scrollToElement = (el) => {
    scroller.scrollTo(el, {
      duration: 750,
      delay: 50,
      smooth: true,
      offset: -61,
    });
  };

  onSaveChangingAboutUs = () => {
    const {
      i5d47460e1c9d440000b02305,
      i5d47469b1c9d440000b02306,
      i5d4746cc1c9d440000b02307,
      i5d4746ff1c9d440000b02308,
      i5d4747481c9d440000b02309,
      i5d4747dd1c9d440000b0230a,
      i5d4748391c9d440000b0230b,
      i5d4748821c9d440000b0230c,
      i5d4749501c9d440000b0230e,
      i5d4749a01c9d440000b0230f,
      i5d474cc61c9d440000b02310,
      i5d474d041c9d440000b02311,
      i5d474d931c9d440000b02315,
      i5d474d7a1c9d440000b02314,
      i5d474d5c1c9d440000b02313,
      i5d474d431c9d440000b02312,
    } = this.state;

    let arrayToUpdate = [];

    if (i5d47460e1c9d440000b02305 != this.state.p5d47460e1c9d440000b02305)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d47460e1c9d440000b02305",
          desc: i5d47460e1c9d440000b02305,
        })
      );

    if (i5d47469b1c9d440000b02306 != this.state.p5d47469b1c9d440000b02306)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d47469b1c9d440000b02306",
          desc: i5d47469b1c9d440000b02306,
        })
      );

    if (i5d4746cc1c9d440000b02307 !== this.state.p5d4746cc1c9d440000b02307)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4746cc1c9d440000b02307",
          desc: i5d4746cc1c9d440000b02307,
        })
      );

    if (i5d4746ff1c9d440000b02308 !== this.state.p5d4746ff1c9d440000b02308)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4746ff1c9d440000b02308",
          desc: i5d4746ff1c9d440000b02308,
        })
      );

    if (i5d4746ff1c9d440000b02308 !== this.state.p5d4746ff1c9d440000b02308)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4746ff1c9d440000b02308",
          desc: i5d4746ff1c9d440000b02308,
        })
      );

    if (i5d4747481c9d440000b02309 !== this.state.p5d4747481c9d440000b02309)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4747481c9d440000b02309",
          desc: i5d4747481c9d440000b02309,
        })
      );

    if (i5d4747dd1c9d440000b0230a !== this.state.p5d4747dd1c9d440000b0230a)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4747dd1c9d440000b0230a",
          desc: i5d4747dd1c9d440000b0230a,
        })
      );
    if (i5d4748391c9d440000b0230b !== this.state.p5d4748391c9d440000b0230b)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4748391c9d440000b0230b",
          desc: i5d4748391c9d440000b0230b,
        })
      );
    if (i5d4748821c9d440000b0230c !== this.state.p5d4748821c9d440000b0230c)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4748821c9d440000b0230c",
          desc: i5d4748821c9d440000b0230c,
        })
      );

    if (i5d4749501c9d440000b0230e !== this.state.p5d4749501c9d440000b0230e)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4749501c9d440000b0230e",
          desc: i5d4749501c9d440000b0230e,
        })
      );

    if (i5d4749a01c9d440000b0230f !== this.state.p5d4749a01c9d440000b0230f)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d4749a01c9d440000b0230f",
          desc: i5d4749a01c9d440000b0230f,
        })
      );

    if (i5d474cc61c9d440000b02310 !== this.state.p5d474cc61c9d440000b02310)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474cc61c9d440000b02310",
          desc: i5d474cc61c9d440000b02310,
        })
      );
    if (i5d474d041c9d440000b02311 !== this.state.p5d474d041c9d440000b02311)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474d041c9d440000b02311",
          desc: i5d474d041c9d440000b02311,
        })
      );

    if (i5d474d931c9d440000b02315 !== this.state.p5d474d931c9d440000b02315)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474d931c9d440000b02315",
          desc: i5d474d931c9d440000b02315,
        })
      );
    if (i5d474d7a1c9d440000b02314 !== this.state.p5d474d7a1c9d440000b02314)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474d7a1c9d440000b02314",
          desc: i5d474d7a1c9d440000b02314,
        })
      );

    if (i5d474d5c1c9d440000b02313 !== this.state.p5d474d5c1c9d440000b02313)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474d5c1c9d440000b02313",
          desc: i5d474d5c1c9d440000b02313,
        })
      );

    if (i5d474d431c9d440000b02312 !== this.state.p5d474d431c9d440000b02312)
      arrayToUpdate.push(
        axios.post("https://api.sr-cpa.co.il/updatecertainparagraph", {
          id: "5d474d431c9d440000b02312",
          desc: i5d474d431c9d440000b02312,
        })
      );
    this.updateContent(arrayToUpdate);
  };

  updateContent = (array) => {
    this.startLoading();
    axios.all(array).then((responses) => {
      this.stopLoading();

      this.props.toastManager.add("SUCCESS", {
        appearance: "success",
        autoDismiss: true,
        pauseOnHover: false,
      });
    });
  };

  componentWillReceiveProps = (props) => {
    if (props.location.state)
      if (props.location.state.param !== this.state.param) {
        this.setState({
          param: props.location.state.param,
        });
        setTimeout(() => {
          this.scrollToElement(props.location.state.param);
        }, 500);
      }
  };

  startLoading = () =>
    this.setState({
      isActive: true,
    });

  stopLoading = () =>
    this.setState({
      isActive: false,
    });

  getContent = (array) => {
    this.startLoading();
    axios.all(array).then((responses) => {
      responses.map((response) => {
        if (response.status === 200) {
          const responseId = "p" + response.data.result._id;
          const responseInput = "i" + response.data.result_id;
          this.setState({
            [responseId]: response.data.result.desc,
            [responseInput]: response.data.result.desc,
          });
        }
      });
      this.stopLoading();
    });
  };

  getTeamPlayers = () => {
    this.startLoading();
    axios.get("https://api.sr-cpa.co.il/getallteamplayers").then((data) => {
      this.stopLoading();
      this.setState({
        teamPlayersList: data.data.result,
      });
    });
  };
  componentDidMount = () => {
    this.getContent(this.state.onGetAboutUs);
    this.getTeamPlayers();
    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch((err) => {
        this.props.setContentEditor(false);
      });
    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true,
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false,
          });
      }
    };
  };

  onChangeTeamPlayer = (id, title, desc) => {
    this.startLoading();
    axios
      .post("/updateteamplayer", {
        id,
        title,
        desc,
      })
      .then((data) => {
        if (data.data.updated) {
          this.getTeamPlayers();
        }
        this.stopLoading();
      });
  };

  render() {
    const { teamPlayersList } = this.state;

    let teamPlayersListArr = [];
    if (teamPlayersList.length) {
      teamPlayersList.map((player, index) => {
        teamPlayersListArr.push(
          <div key={index}>
            {this.props.user.contentEditor && this.state.editPlayer ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "30px 30px" }}
              >
                <EditComp
                  onClick={() =>
                    this.setState({
                      editPlayer: false,
                      editingFor: null,
                    })
                  }
                />
                <SaveComp
                  onClick={() =>
                    this.onChangeTeamPlayer(
                      player._id,
                      this.state.newTitleForPlayer,
                      this.state.newDescForPlayer
                    )
                  }
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <EditComp
                  onClick={() =>
                    this.setState({
                      editPlayer: true,
                      editingFor: player._id,
                      newTitleForPlayer: player.title,
                      newDescForPlayer: player.desc,
                    })
                  }
                />
              )
            )}
            <Element name={player.name}>
              <TeamPlayer
                onChangeDesc={(val) =>
                  this.setState({
                    newDescForPlayer: val,
                  })
                }
                onChangeTitle={(val) =>
                  this.setState({
                    newTitleForPlayer: val,
                  })
                }
                mode={this.state.editingFor === player._id}
                src={player.src}
                desc={player.desc}
                title={player.title}
              />
            </Element>
          </div>
        );
      });
    }
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="אודות סאמי רוזאן ושות'"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />
        <div
          style={{
            paddingTop: 25,
            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "aboutUsContainerEN"
                : "aboutUsContainer"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <Element name="wanted">
                <WantedFormInPages />
              </Element>
            </div>
            <div
              className={
                strings.getLanguage() === "en"
                  ? "marginLeftPercentEN"
                  : "marginLeftPercent"
              }
              style={{
                marginBottom: 20,
                position: "relative",
              }}
            >
              <Element name="audit">
                <div
                  style={{
                    width: "100%",
                    boxShadow: "0px 4px 10px 2px rgba(200,200,200,0.85)",
                    padding: 20,
                    backgroundImage:
                      'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399222/2_hzeflh.jpg")',
                  }}
                >
                  <h1>
                    {this.state.editingAboutUs &&
                    this.props.user.contentEditor ? (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30px 30px",
                          marginBottom: 10,
                        }}
                      >
                        <EditComp
                          onClick={() =>
                            this.setState({
                              editingAboutUs: false,
                            })
                          }
                        />
                        <SaveComp onClick={this.onSaveChangingAboutUs} />
                      </div>
                    ) : (
                      this.props.user.contentEditor && (
                        <div style={{ marginBottom: 10 }}>
                          <EditComp
                            onClick={() =>
                              this.setState({
                                editingAboutUs: true,
                              })
                            }
                          />
                        </div>
                      )
                    )}

                    {this.state.editingAboutUs ? (
                      <span
                        onInput={({ target }) =>
                          this.setState({
                            i5d47460e1c9d440000b02305: target.innerText,
                          })
                        }
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d47460e1c9d440000b02305}
                      </span>
                    ) : (
                      <span>{this.state.p5d47460e1c9d440000b02305}</span>
                    )}
                  </h1>
                  <SubtitleLines />

                  {this.state.editingAboutUs ? (
                    <p
                      onInput={({ target }) =>
                        this.setState({
                          i5d47469b1c9d440000b02306: target.innerText,
                        })
                      }
                      contentEditable
                      suppressContentEditableWarning
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                        border: "1px black solid",
                      }}
                    >
                      {this.state.p5d47469b1c9d440000b02306}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign: "justify",

                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      {this.state.p5d47469b1c9d440000b02306}
                    </p>
                  )}

                  {this.state.editingAboutUs ? (
                    <p
                      onInput={({ target }) =>
                        this.setState({
                          i5d4746cc1c9d440000b02307: target.innerText,
                        })
                      }
                      contentEditable
                      suppressContentEditableWarning
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        border: "1px black solid",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4746cc1c9d440000b02307}</strong>
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4746cc1c9d440000b02307}</strong>
                    </p>
                  )}

                  {this.state.editingAboutUs ? (
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      onInput={({ target }) =>
                        this.setState({
                          i5d4746ff1c9d440000b02308: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        border: "1px black solid",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                      }}
                    >
                      {this.state.p5d4746ff1c9d440000b02308}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                        textAlign: "justify",
                      }}
                    >
                      {this.state.p5d4746ff1c9d440000b02308}
                    </p>
                  )}

                  {this.state.editingAboutUs ? (
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      onInput={({ target }) =>
                        this.setState({
                          i5d4747481c9d440000b02309: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        border: "1px black solid",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4747481c9d440000b02309}</strong>
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4747481c9d440000b02309}</strong>
                    </p>
                  )}

                  {this.state.editingAboutUs ? (
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      onInput={({ target }) =>
                        this.setState({
                          i5d4747dd1c9d440000b0230a: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        border: "1px black solid",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                      }}
                    >
                      {this.state.p5d4747dd1c9d440000b0230a}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                        textAlign: "justify",
                      }}
                    >
                      {this.state.p5d4747dd1c9d440000b0230a}
                    </p>
                  )}
                  {this.state.editingAboutUs ? (
                    <p
                      contentEditable
                      suppressContentEditableWarning
                      onInput={({ target }) =>
                        this.setState({
                          i5d4748391c9d440000b0230b: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        border: "1px black solid",
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4748391c9d440000b0230b}</strong>
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4748391c9d440000b0230b}</strong>
                    </p>
                  )}
                  {this.state.editingAboutUs ? (
                    <p
                      suppressContentEditableWarning
                      contentEditable
                      onInput={({ target }) =>
                        this.setState({
                          i5d4748821c9d440000b0230c: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                        border: "1px black solid",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      {this.state.p5d4748821c9d440000b0230c}
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign: "justify",

                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      {this.state.p5d4748821c9d440000b0230c}
                    </p>
                  )}
                  {this.state.editingAboutUs ? (
                    <p
                      suppressContentEditableWarning
                      contentEditable
                      onInput={({ target }) =>
                        this.setState({
                          i5d4749501c9d440000b0230e: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        border: "1px black solid",
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4749501c9d440000b0230e}</strong>
                    </p>
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                    >
                      <strong>{this.state.p5d4749501c9d440000b0230e}</strong>
                    </p>
                  )}

                  <ul
                    style={{
                      fontSize: "1.25rem",
                      paddingTop: 10,
                      textAlign: "justify",

                      paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                      paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                    }}
                  >
                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474cc61c9d440000b02310}
                      </li>
                    ) : (
                      <li>{this.state.p5d474cc61c9d440000b02310}</li>
                    )}
                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474d041c9d440000b02311}
                      </li>
                    ) : (
                      <li>{this.state.p5d474d041c9d440000b02311}</li>
                    )}

                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474d431c9d440000b02312}
                      </li>
                    ) : (
                      <li>{this.state.p5d474d431c9d440000b02312}</li>
                    )}

                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474d5c1c9d440000b02313}
                      </li>
                    ) : (
                      <li>{this.state.p5d474d5c1c9d440000b02313}</li>
                    )}

                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474d7a1c9d440000b02314}
                      </li>
                    ) : (
                      <li>{this.state.p5d474d7a1c9d440000b02314}</li>
                    )}
                    {this.state.editingAboutUs ? (
                      <li
                        contentEditable
                        suppressContentEditableWarning
                        style={{ border: "1px black solid" }}
                      >
                        {this.state.p5d474d931c9d440000b02315}
                      </li>
                    ) : (
                      <li>{this.state.p5d474d931c9d440000b02315}</li>
                    )}
                  </ul>

                  {this.state.editingAboutUs ? (
                    <p
                      suppressContentEditableWarning
                      contentEditable
                      onInput={({ target }) =>
                        this.setState({
                          i5d4749a01c9d440000b0230f: target.innerText,
                        })
                      }
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign:
                          strings.getLanguage() === "en" ? "left" : "right",
                        border: "1px black solid",
                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.p5d4749a01c9d440000b0230f,
                      }}
                    />
                  ) : (
                    <p
                      style={{
                        marginTop: 15,
                        fontSize: "1.25rem",
                        paddingTop: 10,
                        textAlign: "justify",

                        paddingRight: strings.getLanguage() === "en" ? 0 : 20,
                        paddingLeft: strings.getLanguage() === "en" ? 20 : 0,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.state.p5d4749a01c9d440000b0230f,
                      }}
                    />
                  )}
                </div>
              </Element>
              <hr />
              <Element name="team">
                <div
                  style={{
                    boxShadow: "0px 4px 10px 2px rgba(200,200,200,0.85)",

                    padding: 25,
                    background: "transparent",
                  }}
                >
                  <h1>אנחנו</h1>
                  <SubtitleLines />
                  {teamPlayersListArr}

                  {/* <TeamPlayer
                    src="https://res.cloudinary.com/tatwertech/image/upload/v1564680012/avatar_zgw5cc.png"
                    title="אגבאריה שריף- רואה חשבון"
                    desc={`בוגר תואר ראשון מינהל עסקים וחשבונאות בשנת 2001.
רשיון רואה חשבון C.P.A משנת 2005 .
מומחה לעבודות ביקורת חברות העוסקות בתיירות הכנת דוחות תזרים ותקציבים שנתיים     והתנהלות מול הבנקים
מומחה במתן שירותים לגופים מוסדיים בשוק ההון, כמו ביקורת פנימית, עמידה בדרישות החוק (רגולציה), סקרי מעילות והונאות, סקרי סיכונים וסקרי ציות וכו'.
במתן שירותי ביקורת חיצונית לחברות פרטיות ולעמותות והכנת דוחות אישיים והצהרות הון ליחידים.
התמחה בביקורת חיצונית בחברות פרטיות, ציבוריות ומלכ"רים, מיסים וייעוץ כלכלי לתאגידים, רשויות מקומיות, משרדי ממשלה ודוחות אישיים והצהרות הון לפרטיים.`}
                  /> */}
                </div>
              </Element>
            </div>
          </div>
        </div>
        <BlogsCarousel />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

const withRouterAboutUs = withRouter(AboutUsPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(withRouterAboutUs));
