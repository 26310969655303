import React from "react";
import { withRouter } from "react-router-dom";
import "./GreyButton.css";

const GreyButton = props => {
  if (props.purple)
    return (
      <button
        onClick={props.onPress && props.onPress}
        className={
          props.transitionColor ? "purpleButton color" : "purpleButton"
        }
        type={props.type}
        style={props.style}
      >
        {props.icon === "arrow" ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {props.title} <a className="fas fa-arrow-left arrowlef" />
          </span>
        ) : (
          <span> {props.title} </span>
        )}
      </button>
    );
  else if (props.black)
    return (
      <button
        onClick={props.onPress && props.onPress}
        className={props.transitionColor ? "blackButton color" : "blackButton"}
        type={props.type}
        style={props.style}
      >
        {props.icon === "arrow" ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {props.title} <a className="fas fa-arrow-left arrowlef" />
          </span>
        ) : (
          <>
            {!props.noEarth && (
              <i
                style={{ paddingRight: 5 }}
                className="fas fa-globe-americas"
              />
            )}
            <span> {props.title} </span>
          </>
        )}
      </button>
    );
  else if (props.grey)
    return (
      <button
        onClick={props.onPress && props.onPress}
        className="grey"
        type={props.type}
        style={props.style}
      >
        {props.title}
      </button>
    );
  else if (props.grey2)
    return (
      <button
        onClick={props.onPress && props.onPress}
        className="grey2"
        type={props.type}
        style={props.style}
      >
        {props.icon === "arrow" ? (
          <span
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {props.title} <a className="fas fa-arrow-left arrowlef" />
          </span>
        ) : (
          <span> {props.title} </span>
        )}
      </button>
    );
  else
    return (
      <button
        onClick={props.onPress && props.onPress}
        className={props.transitionColor ? "greyButton color" : "greyButton"}
        style={props.style}
        type={props.type}
      >
        {props.title}
      </button>
    );
};

export default withRouter(GreyButton);
