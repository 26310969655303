import React from "react";
import GreyButton from "../GreyButton/GreyButton";
import SubtitleLines from "../SubtitleLines/SubtitleLines";
import "./WantedFormInPages.css";
import strings from "../../localization/Localization";

const WantedFormInPages = props => {
  return (
    <div
      style={props.style}
      className={
        strings.getLanguage() === "en"
          ? "wantedFormContainerEN"
          : "wantedFormContainer"
      }
    >
      <h1
        style={{ textAlign: strings.getLanguage() === "en" ? "left" : "right" }}
      >
        {strings.wanted}
      </h1>
      <SubtitleLines ltr />
      <p style={{ marginTop: 15, fontSize: "1.25rem" }}>{strings.wanteddesc}</p>
      <p style={{ marginTop: 15, fontSize: "1.25rem" }}>
        <strong> jobs@sr-cpa.co.il</strong>
      </p>
    </div>
  );
};

export default WantedFormInPages;
