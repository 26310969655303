import React, { Component } from "react";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import NavBar from "../../components/navBar/navBar";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import TeamPlayer from "../../components/TeamPlayer/TeamPlayer";
import SubtitleLines from "../../components/SubtitleLines/SubtitleLines";
import BlogsCarousel from "../../components/sections/BlogsCarousel/BlogsCarousel";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import "./LawServicesPage.css";
import axios from "axios";
import { setContentEditor } from "../../redux/actions/firstActions";
import EditComp from "../../components/EditComp/EditComp";
import SaveComp from "../../components/SaveComp/SaveComp";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { withToastManager } from "react-toast-notifications";
import strings from "../../localization/Localization";

class LawServicesPage extends Component {
  state = {
    activated: false,
    isActive: false,
    lawsServices: [],
  };
  setNavbarActivated = (bool) =>
    this.setState({
      activated: bool,
    });

  onGetAllLawsServices = () => {
    axios.get("https://api.sr-cpa.co.il/getalllawservices").then((data) => {
      this.setState({
        lawsServices: data.data.result,
      });
    });
  };
  componentDidMount = () => {
    this.onGetAllLawsServices();

    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }

          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      })
      .catch((err) => {
        this.props.setContentEditor(false);
      });
    let windowOffestTop = document.getElementsByClassName("nav")[0].offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset - windowOffestTop > 0) {
        if (!this.state.fixed)
          this.setState({
            fixed: true,
          });
      } else {
        if (this.state.fixed)
          this.setState({
            fixed: false,
          });
      }
    };
  };

  startLoading = () =>
    this.setState({
      isActive: true,
    });

  stopLoading = () =>
    this.setState({
      isActive: false,
    });

  onChangeService = (id, title, desc) => {
    this.startLoading();
    axios
      .post("/updatelawservice", {
        id,
        title,
        desc,
      })
      .then((data) => {
        if (data.data.updated) {
          this.onGetAllLawsServices();
        }
        this.stopLoading();
      });
  };

  render() {
    const { lawsServices } = this.state;

    let lawsServicesArr = [];
    if (lawsServices.length) {
      lawsServices.map((service, index) => {
        lawsServicesArr.push(
          <div key={index}>
            {this.props.user.contentEditor && this.state.editService ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "30px 30px" }}
              >
                <EditComp
                  mode={this.state.editService}
                  onClick={() =>
                    this.setState({
                      editService: false,
                      editingFor: null,
                    })
                  }
                />
                <SaveComp
                  onClick={() => {
                    this.onChangeService(
                      service._id,
                      this.state.newTitleForService,
                      this.state.newDescForService
                    );
                    this.setState({
                      editService: false,
                      editingFor: null,
                    });
                  }}
                />
              </div>
            ) : (
              this.props.user.contentEditor && (
                <EditComp
                  onClick={() =>
                    this.setState({
                      editService: true,
                      editingFor: service._id,
                      newTitleForService: service.title,
                      newDescForService: service.desc,
                    })
                  }
                />
              )
            )}
            <TeamPlayer
              onChangeDesc={(val) =>
                this.setState({
                  newDescForService: val,
                })
              }
              onChangeTitle={(val) =>
                this.setState({
                  newTitleForService: val,
                })
              }
              title={service.title}
              mode={this.state.editingFor === service._id}
              icon={service.icon}
              desc={service.desc}
            />
          </div>
        );
      });
    }
    return (
      <>
        <LoadingOverlay active={this.state.isActive} text="ממתין.." />
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="תחומי התמחות - עורך דין סאמי מחאמיד"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />

        <div
          style={{
            paddingTop: 25,

            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "lawServicesContainerEN"
                : "lawServicesContainer"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>

            <div
              className={
                strings.getLanguage() === "en" ? "marginLeftEN" : "marginLeft"
              }
            >
              <h1>תחומי התמחות</h1>
              <SubtitleLines />
              {lawsServicesArr}
            </div>
          </div>
        </div>
        <BlogsCarousel />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withToastManager(LawServicesPage));
