import React from "react";
import "./TopAnnouncementBar.css";
import { withRouter } from "react-router-dom";
import axios from "axios";

const TopAnnouncementBar = (props) => {
  return (
    <div className="marq">
      <p
        style={{
          display: "flex",
          justifyContent: "space-between",
          direction: "rtl",
        }}
      >
        <a
          target="_blank"
          href="https://www.waze.com/ul?ll=32.52229448%2C35.15527125&navigate=yes&zoom=17"
        >
          <i
            className="fab fa-waze"
            style={{
              color: "white",
              fontSize: "1.3rem",
              marginLeft: 5,
            }}
          />
          Waze
        </a>
        <span>{new Date().toLocaleDateString("he-il")}</span>
        <span>
          <i className="fas fa-phone" /> טלפון: 04-6112525
        </span>
        <span>
          <i className="fas fa-fax" /> פקס: 04-6310265
        </span>
        <span>
          <i
            className="fas fa-phone-volume"
            style={{ transform: "rotateY(180deg)" }}
          />
          טלפון למקרים דחופים: 052-2515623
        </span>

        <span style={{ fontWeight: 700 }}>
          <i className="fas fa-envelope" /> אימייל: info@sr-cpa.co.il
        </span>
        <span
          onClick={() => {
            if (props.auth) {
              axios
                .get("https://api.sr-cpa.co.il/logout")
                .then((data) => window.location.reload());
            } else props.history.push("/login");
          }}
          style={{ cursor: "pointer" }}
        >
          {props.auth ? "יציאה" : "כניסת צוות"}
        </span>
        <span style={{ cursor: "pointer" }}>
          <span>English</span>
          <i style={{ paddingRight: 5 }} className="fas fa-globe-americas" />
        </span>
      </p>
    </div>
  );
};

export default withRouter(TopAnnouncementBar);
