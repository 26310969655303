import React from "react";

const EditComp = props => {
  return (
    <i
      style={{
        display: "block",
        width: "fit-content",
        fontSize: "1.2rem",
        cursor: "pointer",
        color: props.mode ? "red" : "black",
        marginLeft: props.noMarginLeft ? 0 : 50
      }}
      className={props.mode ? "fas fa-times-circle" : "fas fa-edit"}
      onClick={props.onClick}
    />
  );
};

export default EditComp;
