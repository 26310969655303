import React from "react";
import "./BlogItem.css";
import { withRouter } from "react-router-dom";

const BlogItem = props => {
  return (
    <div
      onClick={() => {
        props.history.push(`/article?id=${props.id}`);
        setTimeout(() => window.location.reload(), 0);
      }}
    >
      <div
        style={{
          position: "relative",
          marginLeft: 10,
          marginRight: 10,
          direction: "rtl",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            overflow: "hidden",
            position: "relative",
            width: "fit-content",
            height: "fit-content",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <img
            className="blogItemImg"
            style={{
              objectFit: "contain",
              width: "100%",
              height: 130,
              cursor: "pointer"
            }}
            src={props.src}
          />
        </div>
        <h3
          style={{
            textAlign: "center",
            minHeight: 50
          }}
        >
          <span
            style={{
              cursor: "pointer",
              width: "fit-content",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            {props.title}
          </span>
        </h3>
        <h4>
          <span>
            <i className="far fa-clock" />
          </span>
          <span style={{ marginRight: 7 }}>זמן קריאה: {props.time} דקות</span>
        </h4>
      </div>
    </div>
  );
};

export default withRouter(BlogItem);
