import React from "react";
import GreyButton from "../GreyButton/GreyButton";
import "./ArticleItem.css";
import { withRouter } from "react-router-dom";
import strings from "../../localization/Localization";

const ArticleItem = props => {
  return (
    <div
      className={
        strings.getLanguage() === "en"
          ? "blogPost1 marginLeftEN"
          : "blogPost1 marginLeft"
      }
      style={{ direction: "ltr" }}
    >
      <div className="blogPostGrid" style={{ direction: !props.rtl && "rtl" }}>
        <div style={{ display: "flex" }}>
          <img src={props.src} />
        </div>
        <div className="blogPostContent">
          <h2>{props.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: props.desc }} />
        </div>
      </div>
      <div
        className="width50Pr"
        style={{
          marginTop: 10,

          textAlign: "center",
          marginLeft: !props.rtl ? "unset" : "auto"
        }}
      >
        <GreyButton
          onPress={() => props.history.push(`/article?id=${props.id}`)}
          grey2
          title="עוד"
        />
        <div
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "flex-end",
            justifyContent: "center"
          }}
        >
          <p
            style={{
              direction: "rtl",
              marginTop: 10
            }}
          >
            הכותב: {props.author}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            textAlign: "center",
            justifyContent: "center"
          }}
        >
          <p
            style={{
              direction: "rtl",
              marginTop: 10
            }}
          >
            תאריך: {props.date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ArticleItem);
