import React, { Component } from "react";
import GreyButton from "../../components/GreyButton/GreyButton";
import "./BlogsPage.css";
import TopAnnouncementBar from "../../components/TopAnnouncementBar/TopAnnouncementBar";
import EnglishBtn from "../../components/EnglishBtn/EnglishBtn";
import NavBar from "../../components/navBar/navBar";
import ImageBanner from "../../components/ImageBanner/ImageBanner";
import ArticleItem from "../../components/ArticleItem/ArticleItem";
import ContactFormInPages from "../../components/ContactFormInPages/ContactFormInPages";
import WantedFormInPages from "../../components/WantedFormInPages/WantedFormInPages";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setContentEditor } from "../../redux/actions/firstActions";
import { withToastManager } from "react-toast-notifications";
import axios from "axios";
import strings from "../../localization/Localization";

class BlogsPage extends Component {
  state = {
    allArticlesList: [],
  };
  getAllArticles = () => {
    axios.get("https://api.sr-cpa.co.il/getallarticles").then((data) => {
      if (data.status === 200) {
        this.setState({
          allArticlesList: data.data.result,
        });
      }
    });
  };
  componentDidMount = () => {
    this.getAllArticles();
    fetch("/checkauth")
      .then((res) => res.json())
      .then((data) => {
        if (data.auth) {
          if (data.user.rootUser) {
          }
          this.props.setContentEditor(true);
        } else this.props.setContentEditor(false);
      });
  };
  render() {
    const { allArticlesList } = this.state;

    let allArticlesListArr = [];
    if (allArticlesList.length) {
      allArticlesList.map((article, index) => {
        let newDesc =
          article.desc.length > 150
            ? article.desc.substr(0, 150)
            : article.desc;

        allArticlesListArr.unshift(
          <div key={index}>
            <ArticleItem
              rtl={index % 2 === 0}
              title={article.title}
              desc={newDesc}
              src={article.src}
              id={article._id}
              author={article.author}
              date={article.date}
            />
          </div>
        );
      });
    }

    return (
      <>
        <TopAnnouncementBar auth={this.props.user.contentEditor} />
        <EnglishBtn />
        <div className="height35VH">
          <div
            className="minHeight100"
            style={{
              paddingBottom: 10,
              position: "relative",
            }}
          >
            <div
              className={
                this.state.fixed && this.state.activated
                  ? "header fixHead2"
                  : !this.state.activated && this.state.fixed
                  ? "header fixHead"
                  : this.state.activated && !this.state.fixed
                  ? "header fixHead2"
                  : "header"
              }
            >
              <NavBar
                fixed={this.state.fixed}
                setNavbarActivated={this.setNavbarActivated}
                HE
              />
            </div>
          </div>
        </div>

        <ImageBanner
          title="שירותי ראיית חשבון"
          src="https://res.cloudinary.com/tatwertech/image/upload/v1564518940/inside_page_header_uvslqv.jpg"
        />

        <div
          style={{
            paddingTop: 25,
            height: "fit-content",

            backgroundImage:
              'url("https://res.cloudinary.com/tatwertech/image/upload/v1564399230/3_swkhnj.jpg")',
          }}
        >
          <div
            className={
              strings.getLanguage() === "en"
                ? "accountingserviceEN"
                : "accountingservice"
            }
          >
            <div style={{ zIndex: 10 }}>
              <ContactFormInPages />
              <WantedFormInPages />
            </div>
            <div>
              {this.props.user.contentEditor && (
                <i
                  style={{
                    marginBottom: 10,
                    color: "green",
                    fontSize: "1.25rem",
                    cursor: "pointer",
                  }}
                  className="fas fa-plus"
                  onClick={() =>
                    this.props.history.push("/article", {
                      addMode: true,
                    })
                  }
                />
              )}
              <div>{allArticlesListArr}</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContentEditor: (bool) => {
      dispatch(setContentEditor(bool));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.firstReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withToastManager(BlogsPage)));
