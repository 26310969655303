import React from "react";
import { withRouter } from "react-router-dom";
import "./User.css";

const User = props => {
  return (
    <div>
      <img
        className="userImageWr"
        style={{
          height: 150,
          marginRight: "auto",
          marginLeft: "auto",
          background: "grey",
          width: 150,
          boxShadow: "rgba(0, 0, 0, 0.45) 0px 3px 15px 2px",
          marginTop: 10,
          borderRadius: "50%",
          objectFit: "cover",
          cursor: "pointer"
        }}
        onClick={() =>
          props.history.push("/about", {
            param: props.param
          })
        }
        src={props.src}
      />
      <h2 style={{ textAlign: "center", marginTop: 10 }}>{props.title}</h2>
      <h3 style={{ textAlign: "center" }}>{props.subtitle}</h3>
    </div>
  );
};

export default withRouter(User);
