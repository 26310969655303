import React from "react";
import GreyButton from "../GreyButton/GreyButton";
const ImageBanner = props => {
  return (
    <div
      style={{
        position: "relative"
      }}
    >
      <div
        style={{
          width: "100%",
          height: 300,
          background: "black",
          opacity: 0.45,
          position: "absolute",
          zIndex: 2
        }}
      />
      <div
        style={{
          width: "100%",
          height: 300,
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "center"
        }}
      >
        <img
          style={Object.assign(
            {
              width: "100%",
              position: "absolute",
              height: 300,
              objectFit: "cover"
            },
            props.style
          )}
          src={props.src}
        />
        <GreyButton
          black
          noEarth
          style={{
            zIndex: 3,
            display: "block",
            border: "2px white solid",
            padding: "8px 40px",
            borderRadius: 0,
            fontWeight: "bold",
            background: "transparent",
            fontSize: "1.75rem",
            direction: "rtl"
          }}
          title={props.title}
        />
      </div>
    </div>
  );
};

export default ImageBanner;
