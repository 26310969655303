import React from "react";
import "./Service.css";
import { withRouter } from "react-router-dom";

const Service = props => {
  const { links } = props;

  const linkList = [
    props.editing && (
      <span key={-1} style={{ display: "flex", justifyContent: "flex-start" }}>
        <i
          style={{
            paddingLeft: 5,
            marginTop: "auto",
            marginBottom: "auto",
            color: "black",
            cursor: "pointer"
          }}
          className="fas fa-plus"
          onClick={() => {
            props.addService();
          }}
        />

        <i
          style={{
            paddingLeft: 5,
            marginTop: "auto",
            marginBottom: "auto",
            color: "green"
          }}
          className="fas fa-check"
        />
        <input
          style={{
            padding: 3,
            textAlign: "right",
            border: "none",
            background: "transparent",
            borderBottom: "1px black solid"
          }}
          onChange={({ target }) => props.newService(target.value)}
        />
      </span>
    )
  ];
  if (links)
    if (links.length)
      links.map((link, index) => {
        linkList.unshift(
          <span
            key={index}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {props.editing && (
              <i
                style={{
                  paddingLeft: 5,
                  marginTop: "auto",
                  marginBottom: "auto",
                  color: "red",
                  cursor: "pointer"
                }}
                className="fas fa-times-circle"
                onClick={() => props.removeService(index)}
              />
            )}
            <i
              style={{
                paddingLeft: 5,
                marginTop: "auto",
                marginBottom: "auto",
                color: "green"
              }}
              className="fas fa-check"
            />
            <p
              style={{ padding: 3, textAlign: "right" }}
              onClick={() => {
                props.history.push("/accountingservices");
              }}
            >
              <span> {link.desc} </span>
            </p>
          </span>
        );
      });
  return (
    <div className="itemHoverContainerhover" style={{ position: "relative" }}>
      <div className="itemHover" style={{ textAlign: "center" }}>
        <div>
          <i
            style={{ fontSize: "5rem", textAlign: "center", color: "#818286" }}
            className={props.icon}
          />
        </div>
        {props.editing ? (
          <h2
            contentEditable
            suppressContentEditableWarning
            onInput={({ target }) => props.onInput(target.innerText)}
            style={{
              cursor: "text",
              textAlign: "center",
              border: "1px black solid",
              marginTop: 30
            }}
          >
            {props.title}
          </h2>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: 30 }}>
            <span> {props.title} </span>
          </h2>
        )}

        <hr className="title-shah" />
        <div
          className="hid-content"
          style={{
            maxWidth: 200,
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          {linkList}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Service);
